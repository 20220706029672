import { apiSlice } from "./api.service";

export const productApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // create product service: POST - /create/product
    createProduct: builder.mutation({
      query: (body) => ({
        url: "/create/product",
        method: "POST",
        body,
        formData: true,
      }),
      invalidatesTags: ["update_product"],
    }),

    // get product service: GET - /get/product/:type
    getProduct: builder.query({
      query: (type) => `/get/product/${type}`,
      providesTags: ["update_product"],
    }),

    getProductById: builder.query({
      query: (id) => `/get/product/${id}`,
      providesTags: ["update_product"],
    }),

    // delete product service: DELETE - /delete/product/:id
    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `/delete/product/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["update_product"],
    }),

    // update product service: PUT - /update/product/:id
    updateProduct: builder.mutation({
      query: ({ id, body }) => ({
        url: `/update/product/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["update_product"],
    }),

    // install product service: GET - /install/product/:type
    installProduct: builder.query({
      query: (type) => `/install/product/${type}`,
    }),

    // {{local}}/set/product/img/105c59
    // set product image service: POST - /set/product/img/:id
    setProductImg: builder.mutation({
      query: ({ id, body }) => ({
        url: `/set/product/img/${id}`,
        method: "POST",
        body,
        formData: true,
      }),
      invalidatesTags: ["update_product"],
    }),
  }),
});

export const {
  useCreateProductMutation,
  useGetgetProductByIdQuery,
  useGetProductQuery,
  useDeleteProductMutation,
  useUpdateProductMutation,
  useInstallProductQuery,
  useSetProductImgMutation,
} = productApi;
