import React, { useState } from "react";
import "./style.css";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { NumericFormat, PatternFormat } from "react-number-format";

export const Organization = () => {
  const [show, setShow] = useState({ old: false, new: false, repeat: false });
  const [logo, setLogo] = useState(null);

  const handleShow = (type) => {
    setShow((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  return (
    <div className="profile">
      <form className="profile__form">
        <div className="profile__form__left">
          <h1>Название организации/ ФИО</h1>
          <div>
            <label>
              <input type="email" placeholder="Емаил" />
            </label>
            <label>
              <input
                type={show.old ? "text" : "password"}
                placeholder="Старый пароль"
              />
              <button onClick={() => handleShow("old")} type="button">
                {show.old ? <IoEyeOutline /> : <IoEyeOffOutline />}
              </button>
            </label>
            <label>
              <input
                type={show.new ? "text" : "password"}
                placeholder="Новый пароль"
              />
              <button onClick={() => handleShow("new")} type="button">
                {show.new ? <IoEyeOutline /> : <IoEyeOffOutline />}
              </button>
            </label>
            <label>
              <input
                type={show.repeat ? "text" : "password"}
                placeholder="Повторите пароль"
              />
              <button onClick={() => handleShow("repeat")} type="button">
                {show.repeat ? <IoEyeOutline /> : <IoEyeOffOutline />}
              </button>
            </label>
          </div>

          <div>
            <label>
              <input type="tel" placeholder="Номер телефона" />
              <PatternFormat
                format="+7 (###) #### ###"
                allowEmptyFormatting
                mask="_"
                style={{ letterSpacing: "2px" }}
              />
            </label>
            <label>
              <input type="text" placeholder="Фамилия" />
            </label>
            <label>
              <input type="text" placeholder="Имя" />
            </label>
            <label>
              <input type="text" placeholder="Отчество" />
            </label>
          </div>

          <div className="profile__requisites">
            <h1>Реквизиты</h1>
            <label className="wf100">
              <input type="text" placeholder="Название:" />
            </label>
            <label className="wf100">
              <input type="text" placeholder="Юр Адрес:" />
            </label>
            <label className="wf50">
              <input type="text" placeholder="БИК" />
            </label>
            <label className="wf50">
              <input type="text" placeholder="КПП" />
            </label>
            <label className="wf50">
              <input type="text" placeholder="Р/С" />
            </label>
            <label className="wf50">
              <input type="text" placeholder="ОГРН" />
            </label>
            <label className="wf50">
              <input type="text" placeholder="К/С" />
            </label>
            <label className="wf50">
              <input type="text" placeholder="Банк" />
            </label>
          </div>
        </div>

        <div className="profile__form__right">
          <div>
            <p>Процент наценки</p>
            <NumericFormat
              allowLeadingZeros
              thousandSeparator=" "
              suffix=" %"
              placeholder="0 %"
            />
          </div>

          <figure>
            <img
              src={logo ? URL.createObjectURL(logo) : "/icons/pic.svg"}
              alt="logo"
              className={logo ? "selected" : ""}
            />
          </figure>

          <label>
            <input
              type="file"
              hidden
              onChange={(e) => setLogo(e.target.files[0])}
              accept="image/*"
            />
            <img src="/icons/download.svg" alt="" />
            <span>Загрузить логотип</span>
          </label>

          <button>Сохранить</button>
        </div>
      </form>
    </div>
  );
};
