import React, { useEffect, useState } from "react";
import "./cost.css";
import { NavLink, useLocation } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { IoCloseOutline } from "react-icons/io5";
import { enqueueSnackbar as EnSn } from "notistack";
import Select, { components } from 'react-select';
import { useGetProductQuery, useCreateProductMutation, useDeleteProductMutation, useUpdateProductMutation, useSetProductImgMutation } from "../../../context/service/product.service";
import { ExportToExcel, ImportExport } from './import_export';

export const Cost = () => {
  const location = useLocation();

  const product = location.search.split("=").pop() || "material";
  const [edit, setEdit] = useState({ open: false, item: null });
  const [value, setValue] = useState({ name: "", price: "", type: product });
  const handleClose = () => setEdit({ open: false, item: null });
  const [addProduct, setAddProduct] = useState(false);
  const [img, setImg] = useState(null);
  const [selectValue, setSelectValue] = useState(null);

  const lists_material = useGetProductQuery('material').data?.innerData || [];

  // Инициализация list_materials
  let list_materials = [];

  // Проверяем, является ли продукт цветом
  if (product === 'color') {
    list_materials = lists_material.map(el => {
      const image = el.image || ''; // Убедитесь, что если изображения нет, возвращаем пустую строку
      return {
        value: el.id,
        label: el.name,
        image: image,
        description: el.description || '',  // Значение по умолчанию
        artiarticle_number: el.artiarticle_number || '' // Значение по умолчанию
      };
    });
  }

  const { data = null } = useGetProductQuery(product);
  const lists = data?.innerData || null;
  const [page, setPage] = useState(null);
  const all_list = useGetProductQuery('all').data?.innerData || [];

  useEffect(() => {
    switch (product) {
      case "material":
        setPage("Материалы");
        break;
      case "color":
        setPage("Цвета");
        break;
      case "width":
        setPage("Толщины");
        break;
      case "thickness":
        setPage("Высота");
        break;
      case "product":
        setPage("Изделия");
        break;
      case "border":
        setPage("Бортики");
        break;
      case "edge":
        setPage("Кромки");
        break;
      case "options":
        setPage("Дополнительные опции");
        break;
      case "installation":
        setPage("Установка и доставка");
        break;
      case "sink":
        setPage("Мойка или раковина");
        break;
      default:
        setPage("Материалы");
    }
  }, [product]);

  const [createProduct] = useCreateProductMutation();
  const handleCreateProduct = async (e) => {
    e.preventDefault();
    let msg, variant;
    const form = e.target;
    const name = form.name.value;
    const price = form.price.value.replace(/\D/g, "");
    const description = (typeof form.description === "undefined" || form.description.value == null) ? '' : form.description.value;
    const artiarticle_number = (typeof form.artiarticle_number === "undefined" || form.artiarticle_number.value == null) ? '' : form.artiarticle_number.value;
    const param = [];

    // Проверяем, является ли param RadioNodeList или одиночным элементом
    if (form.param) {
        if (form.param.length > 0) {
            // Если param – это RadioNodeList (группа радиокнопок)
            for (const pm of form.param) {
                    param.push(pm.value); // Добавляем только выбранные значения
            }
        } else {
            // Если param – это одиночный Radio input
            param.push(form.param.value); // Добавляем значение одиночного элемента
        }
    }

    const paramJSON = param.length > 0 ? JSON.stringify(param) : '';

    const fdata = new FormData();
    fdata.append("name", name);
    fdata.append("price", price);
    fdata.append("description", description);
    fdata.append("artiarticle_number", artiarticle_number);
    fdata.append("param", paramJSON);
    if (img) fdata.append("image", img);
    fdata.append("type", product);
    const { data = null, error = null } = await createProduct(fdata);

    msg = data ? data.message : error?.data?.message;
    variant = data ? data.variant : error?.data?.variant;
    if (error) return EnSn(msg, { variant });
    EnSn(msg, { variant });
    setAddProduct(false);
     setSelectValue(null);
    setImg(null);
    return form.reset();
  };

  const [updateProduct] = useUpdateProductMutation();
  const handleEdit = async (item) => {
    setEdit({ open: true, item });
    setValue({ name: item.name, price: item.price, description: item.description, param: item.param });

    if (edit.open && edit.item === item) {
      isNaN(value.price) && (value.price = value.price.replace(/\D/g, ""));
      value.price = Number(value.price);
      value.description = value.description == null ? '' : value.description;


      const param = Array.isArray(value.param) ? value.param : []; 
      const radioArray = param.length ? param : [];

      const paramValues = radioArray.length > 0 
          ? radioArray.map(option => option.value !== undefined ? option.value : option) 
          : []; 

      const paramJSON = paramValues.length > 0 ? JSON.stringify(paramValues) : '';

      let set = { 
        name: value.name, 
        price: value.price, 
        type: product, 
        description: value.description, 
        artiarticle_number: value.artiarticle_number 
      };

      // Добавляем param только если paramJSON не пустой
      if (paramJSON) {
        set.param = paramJSON;
      }

      set = { id: item.id, body: set };

      const { data = null, error = null } = await updateProduct(set);
      const msg = data ? data.message : error?.data?.message;
      const variant = data ? data.variant : error?.data?.variant;
      if (error) return EnSn(msg, { variant });
      EnSn(msg, { variant });
    }
  };

  const [deleteProduct] = useDeleteProductMutation();
  const handleDeleteProduct = async (id) => {
    // Запросить подтверждение у пользователя
    const confirmed = window.confirm("Вы уверены, что хотите удалить этот продукт?");

    // Если пользователь не подтвердил удаление, выходим из функции
    if (!confirmed) {
      return;
    }

    let msg, variant;
    const { data = null, error = null } = await deleteProduct(id);

    msg = data ? data.message : error?.data?.message;
    variant = data ? data.variant : error?.data?.variant;

    if (error) return EnSn(msg, { variant });
    EnSn(msg, { variant });
  };

  // Set product image
  const [setProductImg] = useSetProductImgMutation();
  const handleSetProductImg = async (id, img) => {
    const fdata = new FormData();
    fdata.append("image", img);
    let set = { id, body: fdata };
    const { data = null, error = null } = await setProductImg(set);
    const msg = data ? data.message : error?.data?.message;
    const variant = data ? data.variant : error?.data?.variant;
    if (error) return EnSn(msg, { variant });
    EnSn(msg, { variant });
  };

  const handleChange = (selectedOptions) => {
    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setValue(prevValue => ({ ...prevValue, param: selectedValues }));
  };

  function form_add(img, addProduct)
  {
    const isColorProduct = product === 'color';
    const isSinkProduct = (product === 'sink' || product === 'edge' || product === 'border');
    const isDescription = (product === 'color');
    const isDevDop = (product === 'installation' || product === 'options');


      return (        <form
            className={`cost__form ${isColorProduct ? "height_500" : ""} ${addProduct ? "active" : ""}`}
             onSubmit={handleCreateProduct}
          >
            <figure>
              <img
                className={img ? "active" : ""}
                src={img ? URL.createObjectURL(img) : "/icons/pic.svg"}
                alt=""
              />
              <label>
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={(e) => setImg(e.target.files[0])}
                />
                <span>Загрузить</span>
              </label>
            </figure>

            <div className="cost__form-inputs">
              <input type="text" placeholder="Название" name="name" />
              {isColorProduct && (
                <input type="text" placeholder="Артикул" name="artiarticle_number" />
              )}
              <NumericFormat
                name="price"
                allowLeadingZeros
                thousandSeparator=" "
                thousandsGroupStyle="lakh"
                suffix=" ₽"
                placeholder="Стоимость"
              />
              {isColorProduct &&  (
                    <Select className="calculation__select_new" name="param"  classNamePrefix="react-select" isMulti options={list_materials} value={selectValue} onChange={setSelectValue} />
              )}
              {isDescription && (
              <textarea placeholder="Описание" name="description"  />
              )}
              {isDevDop && (
                 <input type="text" placeholder="Тип(шт,км)" name="description" />
              )}
              {isSinkProduct && (
                <Select className="calculation__select_new" name="description" defaultValue={{ value: 'sink', label: 'Раковина' }} classNamePrefix="react-select" options={[{value: 'sink', label: 'Раковина'}, {value: 'washing', label: 'Мойка'}]} />
              )}
              <div>
                <button type="button" onClick={() => setAddProduct(false)}>
                  Отменить
                </button>
                <button type="submit">Сохранить</button>
              </div>
            </div>
          </form>);

  }

  function form_edit(active,item) {

    const isColorProduct = product === 'color';
    const isSinkProduct = (product === 'sink' || product === 'edge' || product === 'border');
    const isDescription = (product === 'color');
    const isDevDop = (product === 'installation' || product === 'options');
    let DefaultColors = [];

    if(isColorProduct) {


      if (isColorProduct && item.param) {
        try {
          const parsedParams = JSON.parse(item.param);

          if (Array.isArray(parsedParams)) {
            DefaultColors = list_materials.filter(material => parsedParams.includes(material.value));
          }
        } catch (error) {
          console.error("Ошибка при парсинге item.param:", error);
          // Возможные действия в случае ошибки, например, установить DefaultColors в пустой массив
        }
      }
    }

      return (<form
                      className={`cost__list-item-form ${active ? "active" : ""}`} 
                    >
                      <button
                        type="button"
                        className="cost__list-item-form-close"
                        onClick={handleClose}
                      >
                        <IoCloseOutline />
                      </button>
                      <figure className={item?.image ? "active" : ""}>
                        <img
                          className="item__img"
                          src={item?.image ? item.image : "/icons/pic.svg"}
                          alt={item?.name}
                        />
                        <label>
                          <input
                            type="file"
                            hidden
                            accept="image/*"
                            onChange={(e) => {
                              handleSetProductImg(item.id, e.target.files[0]);
                              let img = document.querySelector(".item__img");
                              img.src = URL.createObjectURL(e.target.files[0]);
                            }}
                          />
                          <span>Загрузить</span>
                        </label>
                      </figure>

                      <div className="cost__list-item-form-inputs">
                        <input
                          type="text"
                          placeholder="Название"
                          defaultValue={item?.name}
                          onChange={(e) =>
                            setValue({ ...value, name: e.target.value })
                          }
                        />

                        {isColorProduct && (
                                  <input
                                    type="text"
                                    placeholder="Артикул"
                                    defaultValue={item?.artiarticle_number}
                                    onChange={(e) =>
                                      setValue({ ...value, artiarticle_number: e.target.value })
                                    }
                                  />
                        )}


                        <NumericFormat
                          value={item?.price}
                          allowLeadingZeros
                          thousandSeparator=" "
                          thousandsGroupStyle="lakh"
                          suffix=" ₽"
                          placeholder="Стоимость"
                          onChange={(e) =>
                            setValue({ ...value, price: e.target.value })
                          }
                        />
                      {isColorProduct &&  (
                            <Select className="calculation__select_new" name="param" onChange={handleChange} classNamePrefix="react-select" isMulti options={list_materials}
                            defaultValue={DefaultColors} />
                        )}
                        {isDescription && (
                          <textarea
                            placeholder="Описание"
                            defaultValue={item?.description}
                            onChange={(e) =>
                            setValue({ ...value, description: e.target.value })
                          } 
                          />
                        )}
                        {isDevDop && (
                           <input
                            type="text"
                            placeholder="Тип(шт,км)" 
                            defaultValue={item?.description}
                            onChange={(e) =>
                              setValue({ ...value, description: e.target.value })
                            }
                            />
                        )}
                        {isSinkProduct && (
                          <Select 
                          className="calculation__select_new" 
                          name="description"
                          onChange={(e) => 
                            setValue({ ...value, description: e.value })
                          } 
                          defaultValue={
                            item.description === 'sink'
                              ? { value: 'sink', label: 'Раковина' }
                              : { value: 'washing', label: 'Мойка' }
                          }
                          classNamePrefix="react-select"
                          options={[{value: 'sink', label: 'Раковина'}, {value: 'washing', label: 'Мойка'}]} />
                        )}
                      </div>
                    </form>)

  }

  return (
    <div className="cost">
      <div className="cost__menu">
        <NavLink to="/cost?product=material" className={({ isActive }) => isActive && product === 'material' ? 'active' : ''}>Материал</NavLink>
        <NavLink to="/cost?product=color" className={({ isActive }) => isActive && product === 'color' ? 'active' : ''}>Цвет</NavLink>
        <NavLink to="/cost?product=width" className={({ isActive }) => isActive && product === 'width' ? 'active' : ''}>Толщина</NavLink>
        <NavLink to="/cost?product=thickness" className={({ isActive }) => isActive && product === 'thickness' ? 'active' : ''}>Высота</NavLink>
        <NavLink to="/cost?product=product" className={({ isActive }) => isActive && product === 'product' ? 'active' : ''}>Изделие</NavLink>
        <NavLink to="/cost?product=border" className={({ isActive }) => isActive && product === 'border' ? 'active' : ''}>Бортик</NavLink>
        <NavLink to="/cost?product=edge" className={({ isActive }) => isActive && product === 'edge' ? 'active' : ''}>Кромка</NavLink>
        <NavLink to="/cost?product=options" className={({ isActive }) => isActive && product === 'options' ? 'active' : ''}>Дополнительные опции</NavLink>
        <NavLink to="/cost?product=installation" className={({ isActive }) => isActive && product === 'installation' ? 'active' : ''}>Установка и доставка</NavLink>
        <NavLink to="/cost?product=sink" className={({ isActive }) => isActive && product === 'sink' ? 'active' : ''}>Мойка или раковина</NavLink>
      </div>

      <div className="cost__content">
        <div className="cost__header">
          <h2>{page}</h2>
          <ExportToExcel />
          <ImportExport />
          <button onClick={() => setAddProduct(true)}>
            <img src="/icons/add.svg" alt="" />
            <span>Добавить</span>
          </button>
        </div>

        {form_add(img, addProduct)}

        <div className="cost__list-container">
          <ol className="cost__list">
            <li className="cost__list-header">
              <p className="w280">Наименование</p>
              <p className="w100">Стоимость</p>
            </li>
            {lists?.map((item, index) => {
              const active = edit.open && edit.item === item;
              const articl =  product==='color' ? '<small >'+item.artiarticle_number +'</small>' : 
              product==='sink' ? 
                item.description === 'sink'
                              ? 'Раковина'
                              : 'Мойка' : '';
              return (
                <li className="cost__list-item" key={item.id}>
                  <div className="cost__list-item-content">
                    <p className="w280">{item.name} {articl ? <small dangerouslySetInnerHTML={{ __html: articl }} /> : ''}</p>
                    <p className="w100">
                      <NumericFormat
                        value={item?.price}
                        allowLeadingZeros
                        thousandSeparator=" "
                        thousandsGroupStyle="lakh"
                        suffix=" ₽"
                        displayType="text"
                      />
                    </p>

                    <div>
                      <button onClick={() => handleEdit(item)}>
                        {active ? "Сохранить" : "Изменить"}
                      </button>
                      <button onClick={() => handleDeleteProduct(item.id)}>
                        Удалить
                      </button>
                    </div>
                  </div>
                  {form_edit(active,item)}
                </li>
              );
            })}
          </ol>
        </div>
      </div>
    </div>
  );
};
