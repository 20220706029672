import React, { useState } from 'react';
import './Invoices.css'; 
import { enqueueSnackbar as EnSn } from "notistack";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

const File = ({ isOpen, onClose, onPrint, onCreatePdf, checkbox = true }) => {
    const [action, setAction] = useState('print'); // Состояние для выбора действия
    const [fillFiles, setFillFiles] = useState(false); // Состояние для чекбокса

    if (!isOpen) return null;

    const handleSubmit = () => {
        if (action === 'print') {
            if(checkbox == false) {
                onPrint(checkbox);
            } else {
                onPrint(fillFiles);
            }
        } else if (action === 'pdf') {
            if(checkbox == false) {
                onCreatePdf(checkbox);
            } else {
                onCreatePdf(fillFiles);
            }
        }
        onClose();
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
               <div>
                    <label>
                        <input
                            type="radio"
                            value="print"
                            checked={action === 'print'}
                            onChange={(e) => setAction(e.target.value)}
                        />
                        Печать
                    </label>
                </div>
                <div>
                    <label>
                        <input
                            type="radio"
                            value="pdf"
                            checked={action === 'pdf'}
                            onChange={(e) => setAction(e.target.value)}
                        />
                        Скачать PDF
                    </label>
                </div>
            {/* Условный рендеринг чекбокса */}
            {checkbox === true && (
                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={fillFiles}
                            onChange={(e) => setFillFiles(e.target.checked)}
                        />
                        Не заполнять данные клиента
                    </label>
                </div>
            )}
                <button className="send_btn" onClick={handleSubmit}>Применить</button>
            </div>
        </div>
    );
};

export default File;