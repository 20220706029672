import React from "react";
import "./calculation.css";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

export const Calculation = () => {
  return (
    <div className="calculation">
      <div className="archive__container">
        <ol className="archive__list">
          <li className="archive__header">
            <p className="w60">id</p>
            <p className="w170">ФИО</p>
            <p className="w170">Материал и цвет</p>
            <p className="w90">Дата</p>
            <p className="w90">Сумма у обработчика</p>
            <p className="w90">Сумма</p>
            <p className="w90">Наценка</p>
            <p className="w90">Отправлен в расчет?</p>
          </li>

          {lists.map((item) => {
            return (
              <li className="archive__item" key={item.id}>
                <p className="w60">{item.id}</p>
                <p className="w170">{item.name}</p>
                <p className="w170">{item.color}</p>
                <p className="w90">{item.date}</p>
                <p className="w90">{item.sumHandler}</p>
                <p className="w90">{item.sum}</p>
                <p className="w90">{item.markup}</p>
                <p className="w90" style={{ borderRight: "none" }}>
                  {item?.sent ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
                </p>

                <div className="archive__actions">
                  <button>Отправить в работу</button>
                  <button>Открыть</button>
                  <button>Удалить</button>
                </div>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
};

const lists = [
  {
    id: 1,
    name: "Иванов Иван Иванович",
    color: "Staron VD-126 Dawn",
    date: "01.01.2021",
    sumHandler: 1000,
    sum: 1000,
    markup: 100,
    sent: true,
  },
  {
    id: 2,
    name: "Петров Петр Петрович",
    color: "Staron VD-126 Dawn",
    date: "02.01.2021",
    sumHandler: 2000,
    sum: 2000,
    markup: 200,
    sent: false,
  },
  {
    id: 3,
    name: "Сидоров Сидор Сидорович",
    color: "Staron VD-126 Dawn",
    date: "03.01.2021",
    sumHandler: 3000,
    sum: 3000,
    markup: 300,
    sent: true,
  },
];
