import React, { useState, useRef, useEffect  } from "react";
import Select, { components } from 'react-select';

import { IoIosArrowDown } from "react-icons/io";
import { ModalImage, Lightbox } from 'react-modal-image';
import { PatternFormat, NumericFormat } from "react-number-format";
import { MdKeyboardArrowDown, MdAdd, MdOutlineZoomOutMap, MdOutlinePictureAsPdf, MdLocalPrintshop } from "react-icons/md";
import { useGetProductQuery } from "../../context/service/product.service";
import { useCreateCalculatorMutation } from '../../context/service/calculator.service'; 
import { Close } from "../../utils/close";
import { enqueueSnackbar as EnSn } from "notistack";
import Modal from './Modal';
import { useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ReactDOM from 'react-dom/client';

import File from '../archive/File';
import PrintTable from '../archive/PrintTable'; // Импортируем компонент таблицы
import '../archive/PrintStyles.css'; // Импортируем стили для печати


export const Calculation = () => {
  document.title = 'Создание расчетов';
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [aboutOrder, setAboutOrder] = useState(false);
  const aboutorderRef = useRef(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [materialPrice, setMaterialPrice] = useState(0);
  const [extraPrice, setExtraPrice] = useState(0)
  const closeAboutOrder = () => setAboutOrder(false);
  const [createCalculator, { isLoading }] = useCreateCalculatorMutation();
  const user = JSON.parse(localStorage.getItem("user")) || null;
  const [markup, setMarkup] = useState(user?.markup);
  const [markupPrice, setMarkupPrice] = useState(0);
  const navigate = useNavigate();

  const [fullname, setFullname] = useState('');
  const [date, setDate] = useState(new Date().toLocaleDateString("sv-SE"));
  const [discount, setDiscount] = useState(0);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [items, setItems] = useState({
    details: [
      {
        name: "",
        color: "",
        length: 0,
        thickness: 0,
        width: 0,
        area: 0,
        price_metr: 0,
        area  : 0,
        count: 1,
        price : 0
      },
    ],
    borders: [
      {
        model: "",
        height: 0,
        length: 0,
        area  : 0,
        price : 0
      }
    ],
    edge: [
      {
        model: "",
        length: 0,
        price  : 0,
        area  : 0
      }
    ],
    washing: [
      {
        category: "",
        model: "",
        color: "",
        length: 0,
        price: 0,
        count: 1
      }
    ],
    total: {
      details_area: 0,
      details_price: 0,
      borders_area: 0,
      borders_price: 0,
      edge_area: 0,
      edge_price: 0,
      washing_area: 0,
      washing_price: 0
    }
  });
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageTitle, setSelectedImageTitle] = useState(null);

  useEffect(() => {
    Calculation_sum(items);
  }, [markup, extraPrice]); // Зависимость от markup


  const handleDublicateElement = (element) => {

      const newItemTemplate = {
          details: {
              name: "",
              color: "",
              length: 0,
              thickness: 0,
              width: 0,
              count: 1,
              area: 0,
              price_metr: 0,
              price: 0
          },
          borders: {
              model: "",
              height: 0,
              length: 0,
              area: 0,
              price: 0
          },
          edge: {
              model: "",
              length: 0,
              price: 0,
              area: 0
          },
          washing: {
              category: "",
              model: "",
              color: "",
              count: 1,
              price: 0
          },
      };

      // Проверяем, есть ли элементы в items[element] и заполнено ли поле color
      const existingItems = items[element];
      const color = existingItems.length > 0 && existingItems[existingItems.length - 1].color ? existingItems[existingItems.length - 1].color : "";
      // Создаем новый элемент, копируя значение color, если оно заполнено
      if(element == 'details') {

        const last_element = existingItems[existingItems.length - 1];
        const last_element_index = options_product.findIndex(option => option.value === last_element['name']['value']);
        newItemTemplate['details']['name'] = options_product[last_element_index+1];
        // Если thickness не выбрано (пустое), устанавливаем значение
        if (!newItemTemplate['details']['thickness']) {
            // Если selectedThickness заполнен, используем его значение
            if (selectedThickness) {
                newItemTemplate['details']['thickness'] = selectedThickness;
            } else {
                // Иначе устанавливаем толщину на основе индекса
                if ((last_element_index + 1) < 5 || (last_element_index + 1) === 6) {
                    newItemTemplate['details']['thickness'] = options_width.find(option => option.label === "40") || { label: "40" };
                } else if ((last_element_index + 1) === 8 || (last_element_index + 1) === 7 || (last_element_index + 1) === 5) {
                    newItemTemplate['details']['thickness'] = options_width.find(option => option.label === "12") || { label: "12" };
                }
            }
        }
      }
      newItemTemplate[element]['color'] = color;
      const newItem = {
          ...newItemTemplate[element],
          details: {
              ...newItemTemplate[element]
          }
      };

      setItems((prevState) => ({
          ...prevState,
          [element]: [
              ...prevState[element],
              newItem
          ]
      }));
  };

  const handleRemoveElement = (element, index) => {
    setItems((prevState) => {
      const newList = prevState[element].filter((_, i) => i !== index);
      return { ...prevState, [element]: newList };
    });
  };

  const [extras, setExtras] = useState({
    install: {},
    options: {}
  });

  const installCount = Array.isArray(extras['install']) ? extras['install'].length : 0;
  const optionsCount = Array.isArray(extras['options']) ? extras['options'].length : 0;

  const handleQuantityChange = (type, index, newCount) => {
    setExtras((prevExtras) => {
      // Копируем предыдущие значения
      const updatedExtras = { ...prevExtras };

      // Определяем, с каким массивом работаем (options или install)
      const targetArray = type === 'options' ? updatedExtras.options : updatedExtras.install;

      // Если массив существует и элемент с таким индексом есть
      if (Array.isArray(targetArray)) {
        const updatedArray = targetArray.map((item, i) =>
          i === index ? { ...item, count: newCount } : item
        );

        // Обновляем массив в зависимости от типа
        if (type === 'options') {
          updatedExtras.options = updatedArray;
        } else if (type === 'install') {
          updatedExtras.install = updatedArray;
        }
      }

      // Вычисляем общую стоимость
      const calculateTotalPrice = (items) => {
        return items.reduce((sum, item) => {
          const price = item.price || 0;
          const count = item.count || 0;
          return sum + price * count;
        }, 0);
      };

      const totalInstallPrice = Array.isArray(updatedExtras.install)
        ? calculateTotalPrice(updatedExtras.install)
        : 0;

      const totalOptionsPrice = Array.isArray(updatedExtras.options)
        ? calculateTotalPrice(updatedExtras.options)
        : 0;

      const totalExtraPrice = totalInstallPrice + totalOptionsPrice;
      setExtraPrice(totalExtraPrice);

      const newTotalPrice = materialPrice + totalExtraPrice;
      setTotalPrice(newTotalPrice);

      // Возвращаем обновлённый объект extras
      return updatedExtras;
    });
  };

  const handleRemoveOption = (type, index) => {
    setExtras((prevExtras) => {
      // Копируем предыдущие значения
      const updatedExtras = { ...prevExtras };

      // Определяем, с каким массивом работаем (options или install)
      const targetArray = type === 'options' ? updatedExtras.options : updatedExtras.install;

      // Если массив существует, удаляем элемент по индексу
      if (Array.isArray(targetArray)) {
        const updatedArray = targetArray.filter((_, i) => i !== index);

        // Обновляем массив в зависимости от типа
        if (type === 'options') {
          updatedExtras.options = updatedArray;
        } else if (type === 'install') {
          updatedExtras.install = updatedArray;
        }
      }

      // Вычисляем общую стоимость
      const calculateTotalPrice = (items) => {
        return items.reduce((sum, item) => {
          const price = item.price || 0;
          const count = item.count || 0;
          return sum + price * count;
        }, 0);
      };

      const totalInstallPrice = Array.isArray(updatedExtras.install)
        ? calculateTotalPrice(updatedExtras.install)
        : 0;

      const totalOptionsPrice = Array.isArray(updatedExtras.options)
        ? calculateTotalPrice(updatedExtras.options)
        : 0;

      const totalExtraPrice = totalInstallPrice + totalOptionsPrice;
      setExtraPrice(totalExtraPrice);

      const newTotalPrice = materialPrice + totalExtraPrice;
      setTotalPrice(newTotalPrice);

      // Возвращаем обновлённый объект extras
      return updatedExtras;
    });
  };


  const material_list = useGetProductQuery('material').data?.innerData || [];
  const colors_list = useGetProductQuery('color').data?.innerData || [];
  const width_list = useGetProductQuery('width').data?.innerData || [];
  const thickness_list = useGetProductQuery('thickness').data?.innerData || [];
  const product_list = useGetProductQuery('product').data?.innerData || [];
  const border_list = useGetProductQuery('border').data?.innerData || [];
  const edge_list = useGetProductQuery('edge').data?.innerData || [];
  const washing_list = useGetProductQuery('sink').data?.innerData || [];
  const install_list = useGetProductQuery('installation').data?.innerData || [];
  const options_list = useGetProductQuery('options').data?.innerData || [];

  const options_colors = colors_list.map((el, i) => {
    const image = el.image != null ? el.image : '';
    return({value: el.id, label: el.artiarticle_number, name: el.name, image: image, description: el.description, artiarticle_number: el.artiarticle_number, price: el.price, param: el.param}
  )});

  const [optionsColors, setOptionsColors] = useState([]);
  const [optionsWashing, setOptionsWashing] = useState([]);
  const options_width = width_list.map((el, i) => {return({value: el.id, label: el.name, price: el.price})});
  const options_thickness = thickness_list.map((el, i) => {return({value: el.id, label: el.name, price: el.price})});

  const options_product = product_list.map((el, i) => {return({value: el.id, label: el.name, price: el.price})});
  const options_border = border_list.map((el, i) => {return({value: el.id, label: el.name, price: el.price, image: el.image})});
  const options_edge = edge_list.map((el, i) => {return({value: el.id, label: el.name, price: el.price, image: el.image})});
  const options_washing = washing_list.map((el, i) => {return({value: el.id, label: el.name, price: el.price, image: el.image, category: el.description})});

  const options_install = install_list.map((el, i) => {return({value: el.id, label: el.name, price: el.price, description: el.description})});
  const options_options = options_list.map((el, i) => {return({value: el.id, label: el.name, price: el.price, description: el.description})});

  useEffect(() => {
      if (options_product && options_width) {
          // Проверяем, пусто ли поле name у первого элемента details
          if (!items['details'][0]['name']) {
              items['details'][0]['name'] = options_product.find(option => option.label === "Деталь столешницы 1");
          }

          // Проверяем, пусто ли поле thickness у первого элемента details
          if (!items['details'][0]['thickness']) {
              items['details'][0]['thickness'] = options_width.find(option => option.label === "40");
          }
      }
  }, [options_product, options_width]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedThickness, setSelectedThickness] = useState(null);
  const [modalData, setModalData] = useState({ items: [], style: '' });

  const handleSelectChange = (option) => {
    setSelectedOption(option);
      const updatedItems = { ...items };
      let updateYes = 'no';
      Object.keys(items).forEach((key) => {
        if (key != 'total') {
          updatedItems[key].forEach((item, index) => {
            //if (option && option.value && updatedItems[key][index] && updatedItems[key][index]['color'] == '') {
              updatedItems[key][index]['color'] = option;
              updateYes = 'yes';
            //}
          });
        }
      });
      if(updateYes === 'yes') {
        setItems(updatedItems);
        Calculation_sum(updatedItems);
      }
  };

  const handleThicknessChange = (option) => {
    setSelectedThickness(option);
    const updatedItems = { ...items };
      let updateYes = 'no';
      Object.keys(items).forEach((key) => {
        if (key != 'total') {
          updatedItems[key].forEach((item, index) => {
            //if (option && option.value && updatedItems[key][index] && updatedItems[key][index]['color'] == '') {
              updatedItems[key][index]['thickness'] = option;
              updateYes = 'yes';
            //}
          });
        }
      });
      if(updateYes === 'yes') {
        setItems(updatedItems);
        Calculation_sum(updatedItems);
      }

  };

  useEffect(() => {
        const updatedItems = { ...items };
        let updateYes = 'no';
        Object.keys(items).forEach((key) => {
          if (key != 'total') {
            updatedItems[key].forEach((item, index) => {
              if (selectedThickness && selectedThickness.value && updatedItems[key][index] && updatedItems[key][index]['thickness'] == '') {
                updatedItems[key][index]['thickness'] = selectedThickness;
                updateYes = 'yes';
              }
            });
          }
        });
        if(updateYes === 'yes') {
          Calculation_sum(updatedItems);
        }
    }, [selectedThickness]); // Зависимость от markup

  const openModal = (data = [], style = '', type, name, index, value) => {

      const item = { items: data, style: style, name: name, type: type,index: index, value: value};
      setModalData(item);
      setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalData([]); // Очистка данных модального окна
  };

  const CustomDropdownIndicator = (props) => {
    const handleClick = (event) => {
      event.stopPropagation(); // Остановить всплытие события
      openModal(props.options, props.style, props.type, props.name, props.index, props.selectProps.value); // Передать массив в модальное окно
    };


    return (
      <components.DropdownIndicator {...props}>
        <div onClick={handleClick} style={{ cursor: 'pointer', padding: '8px' }}>
          <IoIosArrowDown />
        </div>
      </components.DropdownIndicator>
    );
  };

  const validateItemsAndExtras = (items, extras) => {
    let isValid = true;
    let errorMessages = [];

    // Проверка `items`

    if (!Array.isArray(items) || items.length === 0) {
        //isValid = false;
        //errorMessages.push("Элементы не могут быть пустыми.");
    } else {
        items.forEach((item, index) => {
            if (!item.someField) {  // Замените `someField` на реальные ключи в ваших объектах
                isValid = false;
                errorMessages.push(`Элемент с индексом ${index} недействителен.`);
            }
        });
    }


    return { isValid, errorMessages };
  };

  const handleCreateCalc = async (e, work = 0, print = false) => {
      e.preventDefault();
      let msg, variant;

      const form = e.target;

      const material = selectedGrade;
      const color = selectedOption;
      const thickness = selectedThickness;
      const markup = user?.markup;
      const material_price = materialPrice;
      const extras_price = extraPrice;
      const markup_price = markupPrice;
      const status = work == 1 ? 1 : 0;

      // Выполняем валидацию
      const { isValid, errorMessages } = validateItemsAndExtras(items, extras);

      if (!isValid) {
          msg = errorMessages.join(" ");  // Соединяем сообщения об ошибках
          variant = "error";
          EnSn(msg, { variant }); // Показываем уведомление о ошибке
          return;
      }

        const payload = {
            date,
            fullname,
            phone,
            email,
            discount: Number(discount) || 0,
            material,
            color,
            thickness,
            items,
            extras,
            markup: Number(markup) || 0,
            material_price,
            extras_price,
            markup_price,
            status
        };

      // Отправка запроса через API
    const { data = null, error = null } = await createCalculator(payload);

    msg = data ? data.message : error?.data?.message;
    variant = data ? data.variant : error?.data?.variant;
    
    if (error) {
        EnSn(msg, { variant });
        return;
    }

    // Переадресация на страницу /archive/item/{id} после успешного создания
    if (data && data.innerData && data.innerData.id) { // Предполагаем, что API возвращает id созданного калькулятора
        navigate(`/archive/item/${data.innerData.id}`, { state: { printResults: print } });
    } else {
        EnSn("Не удалось получить ID созданного калькулятора.", { variant: "error" });
    }

    EnSn(msg, { variant });

  };

  const handleSendToWork = (event) => {
    handleCreateCalc(event, 1);
  };

  const handleSave = (event) => {
    handleCreateCalc(event);
  };

  const handlePrint = (name = false, items) => {
    print(name);
  };

  const Calculation_sum = async (items) => {
    const updatedItems = { ...items };
    const markupFactor = markup ? 1 + markup / 100 : 1; // Получаем коэффициент на основе процента
    let totalMarkup = 0;
    Object.keys(items).forEach((key) => {
        if (key === 'details') {
            updatedItems[key].forEach((item, index) => {
              const length = parseFloat(String(item.length).replace(/\s+/g, '')) || 0; // Заменяем все пробелы
              const width = parseFloat(String(item.width).replace(/\s+/g, '')) || 0; // Заменяем все пробелы

              // Вычисляем площадь и округляем до двух десятичных знаков
              updatedItems[key][index]['area'] = parseFloat(
                ((length * width / 1000000) * parseFloat(String(item['count']).replace(/\s+/g, ''))).toFixed(2)
              ) || 0;
            });
        }

        if (key !== 'total') {
            updatedItems[key].forEach((item, index) => {
                // Инициализация значений в total только при первой итерации
                if (index === 0) {
                    updatedItems['total'][`${key}_price`] = 0;
                    updatedItems['total'][`${key}_area`] = 0;
                }

                // Расчет цен и площадей в зависимости от типа ключа
                switch (key) {
                    case 'details':
                      // Рассчитываем цену за метр
                      const detailPriceMetr = item['name'].price + item['thickness'].price + item['color'].price;

                      // Рассчитываем коэффициент наценки
                      const markupFactorMetr = markup ? 1 + (markup - discount) / 100 : 1;

                      // Округляем цену за метр до двух знаков после запятой
                      updatedItems[key][index]['price_metr'] = parseFloat((detailPriceMetr * markupFactorMetr).toFixed(0));

                      // Округляем общую цену до двух знаков после запятой
                      updatedItems[key][index]['price'] = parseFloat((item['area'] * detailPriceMetr * markupFactorMetr).toFixed(0));

                      // Рассчитываем базовую цену (без наценки)
                      const detailPrice = item['area'] * detailPriceMetr || 0;

                      // Увеличиваем общую сумму наценки
                      totalMarkup += parseFloat((detailPrice * (markupFactor - 1)).toFixed(2));

                      // Обновляем общую цену и площадь
                      updatedItems['total'][`${key}_price`] = parseFloat((updatedItems['total'][`${key}_price`] + (detailPrice * markupFactor)).toFixed(0));
                      updatedItems['total'][`${key}_area`] = parseFloat((updatedItems['total'][`${key}_area`] + parseFloat(item['area'])).toFixed(2));

                      break;

                    case 'edge':
                        // Преобразуем длину в число, удаляя пробелы
                        const edgeLength = parseFloat(String(item['length']).replace(/\s+/g, '')) || 0;

                        // Рассчитываем цену с учётом наценки и округляем до 2 знаков после запятой
                        updatedItems[key][index]['price'] = (edgeLength / 1000 * item['model'].price * markupFactor).toFixed(0);

                        // Рассчитываем базовую цену (без наценки)
                        const edgePrice = (edgeLength / 1000 * item['model'].price) || 0;

                        // Увеличиваем общую сумму наценки
                        totalMarkup += edgePrice * (markupFactor - 1);

                        updatedItems[key][index]['price_metr'] = parseFloat((item['model'].price * markupFactor).toFixed(0));

                        // Обновляем общую площадь и цену
                        updatedItems['total'][`${key}_area`] = parseFloat((updatedItems['total'][`${key}_area`] + (edgeLength / 1000)).toFixed(2));
                        updatedItems['total'][`${key}_price`] = parseFloat((updatedItems['total'][`${key}_price`] + edgePrice * markupFactor).toFixed(0));

                        break;

                    case 'borders':
                        // Преобразуем длину в число, удаляя пробелы
                        const borderLength = parseFloat(String(item['length']).replace(/\s+/g, '')) || 0;

                        // Получаем цену толщины, если она есть
                        const thickness_price = item['thickness'] ? item['thickness'].price : 0;

                        // Получаем цену модели, если она есть и является числом
                        const modelPrice = item['model'] && typeof item['model'].price === 'number' ? item['model'].price : 0;

                        // Вычисляем цену с учётом наценки
                        const calculatedPrice = (borderLength / 1000) * (modelPrice + thickness_price) * markupFactor;
                        updatedItems[key][index]['price'] = !isNaN(calculatedPrice) ? parseFloat(calculatedPrice.toFixed(0)) : 0;

                        // Вычисляем базовую цену (без наценки)
                        const borderPrice = (borderLength / 1000) * (modelPrice + thickness_price) || 0;

                        // Увеличиваем общую сумму наценки
                        totalMarkup += borderPrice * (markupFactor - 1);

                        // Вычисляем общую цену с учётом наценки
                        const totalPrice = borderPrice * markupFactor;

                        updatedItems[key][index]['price_metr'] = parseFloat(((modelPrice + thickness_price) * markupFactor).toFixed(0));

                        // Обновляем общую площадь и цену
                        updatedItems['total'][`${key}_area`] = parseFloat((updatedItems['total'][`${key}_area`] + (borderLength / 1000)).toFixed(2));
                        updatedItems['total'][`${key}_price`] = parseFloat((updatedItems['total'][`${key}_price`] + totalPrice).toFixed(0));

                        break;

                    case 'washing':
                     // Получаем цену и количество без пробелов
                        const price = item['model'].price;
                        const count = item['count'] || 0;

                        // Вычисляем цену
                        updatedItems[key][index]['price'] = parseFloat((price * parseFloat(String(count).replace(/\s+/g, '')) * markupFactor).toFixed(2));

                        const washingPrice = price * parseFloat(String(count).replace(/\s+/g, '')) || 0;

                        // Обновляем маржу
                        totalMarkup += washingPrice * (markupFactor - 1);

                        updatedItems['total'][`${key}_price`] += parseFloat((washingPrice * markupFactor).toFixed(2)) || 0;

                        break;
                }
            });
        }
    });
    setMarkupPrice(totalMarkup); // Устанавливаем итоговую наценку
    const totalMaterialPrice = Object.keys(updatedItems.total)
        .filter(key => key.endsWith('_price')) 
        .reduce((sum, key) => {
            return sum + (parseFloat(updatedItems.total[key]) || 0); 
        }, 0);
    setMaterialPrice(totalMaterialPrice);
    setTotalPrice(totalMaterialPrice + extraPrice);
    setItems(updatedItems);
  };

  const handleModalSave = (type, style,quantities,index,value) => {
    if (style === 'delivery') {
        setExtras((prevExtras) => {
            const updatedExtras = { ...prevExtras }; // Копируем предыдущие значения
            
            const filteredQuantities = quantities.filter(
                (item) => item && item.count !== undefined && item.count > 0
            );

            if (type === 'install') {
                updatedExtras.install = filteredQuantities; // Сохраняем данные для установки
            } else if (type === 'options') {
                updatedExtras.options = filteredQuantities; // Сохраняем данные для опций
            }
            
            // Расчеты для totalPrice на основе обновленных extras
            const totalInstallPrice = Array.isArray(updatedExtras.install) ? updatedExtras.install.reduce((sum, item) => {
                const price = item.price || 0; // Предполагаем, цена может отсутствовать
                const count = item.count || 0; // Количество
                return sum + price * count; // Суммируем цену умноженную на количество
            }, 0) : 0;

            const totalOptionsPrice = Array.isArray(updatedExtras.options) ? updatedExtras.options.reduce((sum, item) => {
                const price = item.price || 0;
                const count = item.count || 0;
                return sum + price * count;
            }, 0) : 0;

            const totalExtraPrice = totalInstallPrice + totalOptionsPrice;
            setExtraPrice(totalExtraPrice); // Устанавливаем полученную сумму в extraPrice
            
            const newTotalPrice = materialPrice + totalExtraPrice;
            setTotalPrice(newTotalPrice);
            return updatedExtras; // Возвращаем обновлённый объект

        });
    } else if (style === 'color') {
        if (quantities && value) {
            const updatedItems = { ...items };
            updatedItems[type][index][quantities] = value;
            setItems(updatedItems);
        } else if (!quantities && type === 'color' && style === 'color') {

      const updatedItems = { ...items };
            let updateYes = 'no';
            Object.keys(items).forEach((key) => {
              if (key != 'total') {
                updatedItems[key].forEach((item, index) => {
                  //if (value && value.value && updatedItems[key][index] && updatedItems[key][index]['color'] == '') {
                    updatedItems[key][index]['color'] = value;
                    updateYes = 'yes';
                  //}
                });
              }
            });
            if(updateYes === 'yes') {
              setItems(updatedItems);
            }

            setSelectedOption(value);
        }
    }

    Calculation_sum(items);
  };

  const handleInputChangeItems = async (event, type, index, name = '') => {

    const target = event.target;
    const value = target ? target.type ==='checkbox' ? target.checked : target.value : event;
    const price = event && event.price ? event.price : '';
    const name_t = target ? target.name : name;

    if (type === 'details' && 
        ['6cfb44', '239ea9', '767b95', 'bb4b17', '99d061'].includes(value.value)) {
        // Проверяем, что thickness равен null
        if (items[type][index]['thickness'] === 0 || 1 === 1) {
            const selectedOption = options_width.find(option => option.value === '4e4c74');
            if (selectedOption ) {
                items[type][index]['thickness'] = selectedOption; // или selectedOption.label, в зависимости от того, что нужно
            }
        }
    }

    if(type === 'details' && value.value == 'e353d7') {
        if (items[type][index]['thickness'] === 0 || 1 === 1) {
            const selectedOption = options_width.find(option => option.value === 'e0cdfa');
            if (selectedOption) {
                items[type][index]['thickness'] = selectedOption; // или selectedOption.label, в зависимости от того, что нужно
            }
        }
    }

    if(type === 'details' && value.value == 'a51faf') {
        if (items[type][index]['thickness'] === 0 || 1 === 1) {
            const selectedOption = options_width.find(option => option.value === '4e4c74');
            if (selectedOption) {
                items[type][index]['thickness'] = selectedOption; // или selectedOption.label, в зависимости от того, что нужно
            }
        }
    }

    if(type === 'details' && value.value == 'ad8d75') {
        if (items[type][index]['thickness'] === 0 || 1 === 1) {
            const selectedOption = options_width.find(option => option.value === 'e0cdfa');
            if (selectedOption ) {
                items[type][index]['thickness'] = selectedOption; // или selectedOption.label, в зависимости от того, что нужно
            }
        }
    }

    if(type === 'details' && value.value == '8726ba') {
        if (items[type][index]['thickness'] === 0 || 1 === 1) {
            const selectedOption = options_width.find(option => option.value === 'e0cdfa');
            if (selectedOption ) {
                items[type][index]['thickness'] = selectedOption; // или selectedOption.label, в зависимости от того, что нужно
            }
        }
    }


    if(name_t == 'category') {

      const filter_washing = options_washing.filter(washing => 
        washing.category && washing.category.trim().toLowerCase() === value.value.trim().toLowerCase()
      );
      setOptionsWashing(filter_washing);
    }
    const updatedItems = items[type].map((item, itemIndex) => {
        if (itemIndex === index) { 
            const updatedItem = {
                ...item, 
                [name_t ? name_t : name]:  value
            };
            return updatedItem;
        }
        return item; // возвращаем элемент без изменений
    });
    const NewUpdatedItems = {
      ...items,
      [type]: [
        ...updatedItems
      ]
    };
    setItems((prevState) => (NewUpdatedItems));

    Calculation_sum(NewUpdatedItems,type);

  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    let msg, variant;
    switch(name) {
      case 'date':
        setDate(value);
        break;
      case 'fullname':
        setFullname(value);
        break;
      case 'phone':
        setPhone(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'discount':
        const discountPercentage = value.replace(' %', '');
        if (user?.role != 'admin' && discountPercentage > user?.markup) {
          msg = 'Максимум ' + user?.markup + '%';
          variant = 'error';
          EnSn(msg, { variant });
          return;
        } else {
         setDiscount(discountPercentage);
          const newMarkup = user?.markup - discountPercentage; // Вычитаем скидку из маркета
          setMarkup(newMarkup); // Устанавливаем новое значение маркет
        }
        break;
    }
  }

  const [selectedGrade, setSelectedGrade] = useState(null);
  const handleGradeClick = (grade) => {

    const filter_color = options_colors.filter(color => {
      if (color.param) {
        try {
          return JSON.parse(color.param).includes(grade.id);
        } catch (error) {
          console.error("Ошибка при парсинге color.param:", error);
          return false; // В случае ошибки, возвращаем false
        }
      }
      return false;
    });
    setSelectedGrade(grade);
    setOptionsColors(filter_color);


    const updatedItems = { ...items };
    let updateYes = 'no';
    Object.keys(items).forEach((key) => {
      if (key != 'total') {
        updatedItems[key].forEach((item, index) => {
          if (item['color'] && item['color'].value && !filter_color.some(color => color.value === item['color'].value)) {
            updatedItems[key][index]['color'] = '';
            updateYes = 'yes';
          }
        });
      }
    });
    if(updateYes === 'yes') {
      setItems(updatedItems);
      Calculation_sum(updatedItems);
    }

    if (selectedOption && selectedOption.value && !filter_color.some(color => color.value === selectedOption.value)) {
        setSelectedOption(null);
    }
  }
  const handleGradeUnselect = () => setSelectedGrade(null);


  const handleLightboxOpen = (material) => {
    setSelectedImage(material.image);
    setSelectedImageTitle(material.name);
    setLightboxOpen(true);
  };

  const handleLightboxClose = () => {
    setLightboxOpen(false);
    setSelectedImage(null);
    setSelectedImageTitle(null);
  };

  const handlePrintButtonClick = () => {
      setIsPrintModalOpen(true); // Открываем модальное окно
  };

  const print = (name = false) => {
    let msg, variant;
      variant = 'error';

      if(selectedGrade === null) {
        EnSn('Заполните материал', { variant });
        return ;
      }

      if(selectedOption === null) {
        EnSn('Заполните цвет', { variant });
        return ;
      }

      if(selectedThickness === null) {
        EnSn('Заполните толщину', { variant });
        return ;
      }
      // Создаем временное окно
      const printWindow = window.open('', '_blank');

      // Проверяем, что новое окно открылось
      if (!printWindow) {
          console.error('Не удалось открыть новое окно для печати.');
          return;
      }
    printWindow.document.write(`
        <html>
            <head>
                <title>Заказ</title>
                <style>
                    /* Здесь можно добавить стили для печати */
                    body {
                        font-family: Arial, sans-serif;
                    }
                </style>
                <link rel="stylesheet" type="text/css" href="/assets/PrintStyles.css" onload="this.onload=null; this.rel='stylesheet'">
            </head>
            <body>
                <div id="print-content"></div> <!-- Контейнер для рендеринга -->
            </body>
        </html>
    `);
    printWindow.document.close(); // Закрываем документ для рендеринга

    printWindow.onload = () => {
        const printContent = printWindow.document.getElementById('print-content');
        const root = ReactDOM.createRoot(printContent); // Создаем корень для рендеринга
        root.render(<PrintTable items={items} color={selectedOption} material={selectedGrade} extras={extras} discount={discount} markup={markup} price={totalPrice} />); // Рендерим компонент в контейнер
        
        // Задержка перед печатью, чтобы компонент успел отрендериться
        setTimeout(() => {
            printWindow.print(); // Открываем диалоговое окно печати
            printWindow.close(); // Закрываем окно после печати
        }, 500); // 500 миллисекунд задержка
    };
  };

  const createPdf = async (name = false) => {
      
    let msg, variant;
      variant = 'error';

      if(selectedGrade === null) {
        EnSn('Заполните материал', { variant });
        return ;
      }

      if(selectedOption === null) {
        EnSn('Заполните цвет', { variant });
        return ;
      }

      if(selectedThickness === null) {
        EnSn('Заполните толщину', { variant });
        return ;
      }

      const printWindow = window.open('', '_blank');
      // Проверяем, что новое окно открылось
      if (!printWindow) {
          console.error('Не удалось открыть новое окно для создания PDF.');
          return;
      }

      // Записываем HTML-структуру в новое окно
      printWindow.document.write(`
          <html>
              <head>
                  <title>Заказ</title>
                  <style>
                      /* Здесь можно добавить стили для печати */
                      body {
                          font-family: Arial, sans-serif;
                      }
                  </style>
                  <link rel="stylesheet" type="text/css" href="/assets/PrintStyles.css" onload="this.onload=null; this.rel='stylesheet'">
              </head>
              <body>
                  <div id="print-content"></div> <!-- Контейнер для рендеринга -->
              </body>
          </html>
      `);
      printWindow.document.close(); // Закрываем документ для рендеринга

      // Ждем, пока окно загрузится
      printWindow.onload = async () => {
          const printContent = printWindow.document.getElementById('print-content');
          const root = ReactDOM.createRoot(printContent); // Создаем корень для рендеринга
          root.render(<PrintTable items={items} color={selectedOption} material={selectedGrade} extras={extras} discount={discount} markup={markup} price={totalPrice} />); // Рендерим компонент в контейнер

          // Даем время для рендеринга компонента
          setTimeout(async () => {
              // Используем html2canvas для создания изображения
              const canvas = await html2canvas(printContent, {
                  scale: 1, // Увеличиваем масштаб для лучшего качества
                  useCORS: true, // Если используются изображения с других доменов
              });

              const imgData = canvas.toDataURL('image/png');
              const pdf = new jsPDF('p', 'mm', 'a4'); // Создаем PDF в портретном режиме, размер A4

              const imgWidth = 210; // Ширина A4 в мм
              const pageHeight = 295; // Высота A4 в мм
              const imgHeight = (canvas.height * imgWidth) / canvas.width;
              let heightLeft = imgHeight;
              let position = 0;

              // Добавляем изображение на первую страницу
              pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
              heightLeft -= pageHeight;

              // Если изображение не помещается на одной странице, добавляем дополнительные страницы
              while (heightLeft >= 0) {
                  position = heightLeft - imgHeight;
                  pdf.addPage();
                  pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                  heightLeft -= pageHeight;
              }

              // Сохраняем PDF
              pdf.save(`Заказ.pdf`);

              // Закрываем временное окно
              printWindow.close();
          }, 500); // 500 миллисекунд задержка
      };
  };




  return (
    <div className="calculation">
    <form onSubmit={handleSave}
    >
      <div className="calculation__header">
        <div className={"about__order_mobile" + (aboutOrder ? " active" : "")}>
          <label onClick={() => setAboutOrder(true)}>
            <span>Данные о заказе:</span>
            <MdKeyboardArrowDown />
          </label>

          <div
            ref={aboutorderRef}
            className={
              "about__order_mobile__content" + (aboutOrder ? " active" : "")
            }
          >
            <p onClick={closeAboutOrder}>
              <span>Данные о заказе:</span>
              <MdKeyboardArrowDown />
            </p>

            <input
              type="date"
              name="date"
              defaultValue={date}
              onChange={handleChange}

            />
            <input
              type="text"
              placeholder="ФИО клиента"
              name="fullname"
              autoComplete="off"
              defaultValue={fullname}
              onChange={handleChange}

            />

            <PatternFormat
              format="+7 (###) ###-##-##"
              allowEmptyFormatting
              mask="_"
              name="phone"
              defaultValue={phone}
              placeholder="Телефон клиента"
              onChange={handleChange}

            />

            <input
              type="email"
              placeholder="Email клиента"
              name="email"
              defaultValue={email}
              autoComplete="off"
              onChange={handleChange}

            />

            <NumericFormat
              allowLeadingZeros
              thousandSeparator=""
              suffix=" %"
              placeholder="Скидка для клиента"
              value={discount || 0}
              name="discount"
              onChange={handleChange}
            />
            <div className="order__price">
              <p>Итоговая цена:</p>
              <NumericFormat
                value={totalPrice}
                allowLeadingZeros
                thousandSeparator=" "
                
                suffix=" ₽"
                displayType="text"

              />
            </div>

            <div className="order__buttons">
              <button type="button"  onClick={handleSendToWork}>Отправить в работу</button>
              <button >Сохранить</button>
            </div>
            <div className="order__buttons">
              <button type="button" onClick={handlePrint}><MdLocalPrintshop /><span>Распечатать</span></button>
              <button type="button" onClick={createPdf}><MdOutlinePictureAsPdf /><span>Скачать PDF</span></button>
            </div>
          <button
            type="button"
            onClick={() => {
              const isConfirmed = window.confirm("Вы уверены, что хотите закрыть текущий расчёт?");
              if (isConfirmed) {
                window.location.reload(); // Перезагрузить страницу
              }
            }}
          >
            Новый расчёт
          </button>
          </div>
        </div>

        <div className="calculation__header_left">
          <div className="calculation__box">
            <h2 className="calculation__title">Марка материала:</h2>

            <ol className="calculation__grades">
              {material_list.map((material) => {
                const isSelected = selectedGrade?.id === material?.id;
                return (
                  <li
                    key={material.id}
                    className={
                      "calculation__grade" + (isSelected ? " active" : "")
                    }
                    onClick={() =>
                      isSelected
                        ? handleGradeUnselect()
                        : handleGradeClick(material)
                    }
                  >

                    <img src={material.image} alt={material.name} />
                    <img
                      src="/icons/checkbox.svg"
                      alt="checkbox"
                      className={"checkbox" + (isSelected ? " active" : "")}
                    />
                  <div className="lightbox-icon"
                  onClick={(e) => {
                    e.stopPropagation(); // Останавливаем распространение события
                    handleLightboxOpen(material);
                  }}>
                    <MdOutlineZoomOutMap />
                  </div>
                  </li>
                );
              })}
            </ol>
          </div>

          <div className="calculation__box">
            <h2 className="calculation__title">Цвет:</h2>
            <Select className="calculation__select_new" name="color" classNamePrefix="react-select" options={optionsColors}
                components={{
                    DropdownIndicator: (props) => (
                        <CustomDropdownIndicator {...{ ...props, type: 'color', style: 'color' }} />
                    ),
                }}
            value={selectedOption}
            onChange={handleSelectChange}
            formatOptionLabel={color => (
              <div className="color_option">
                <div className="span">{color.name}<span>{color.label}</span></div>
                <img src={color.image} />
              </div>
            )}/>

          </div>          
          <div className="calculation__box">
            <h2 className="calculation__title">Толщина изделия:</h2>
            <Select className="calculation__select_new" name="thickness" onChange={handleThicknessChange} classNamePrefix="react-select" options={options_width} formatOptionLabel={width => (
              <div className="color_option">
                <div className="span">{width.label}</div>
              </div>
            )}/>
          </div>
        </div>
        <div className="about__order">
          <h2>Данные о заказе:</h2>
          <input
            type="date"
            name="date"
            defaultValue={date}
            onChange={handleChange}
          />
          <input
            type="text"
            placeholder="ФИО клиента"
            name="fullname"
            autoComplete="off"
            defaultValue={fullname}
            onChange={handleChange}
          />

          <PatternFormat
            format="+7 (###) ###-##-##"
            allowEmptyFormatting
            mask="_"
            name="phone"
            placeholder="Телефон клиента"
            defaultValue={phone}
            onChange={handleChange}
          />

          <input
            type="email"
            placeholder="Email клиента"
            name="email"
            autoComplete="off"
            defaultValue={email}
            onChange={handleChange}
          />
            <NumericFormat
              allowLeadingZeros
              thousandSeparator=""
              suffix=" %"
              placeholder="Скидка для клиента"
              value={discount || 0}
              name="discount"
              onChange={handleChange}
            />

          <div className="order__price">
            <p>Итоговая цена:</p>
            <NumericFormat
              value={totalPrice}
              allowLeadingZeros
              thousandSeparator=" "
              
              suffix=" ₽"
              displayType="text"
            />
          </div>

          <div className="order__buttons">
            <button  type="button" onClick={handleSendToWork}>Отправить в работу</button>
            <button>Сохранить</button>
          </div>
          <div className="order__buttons">
            <button type="button" onClick={handlePrint}><MdLocalPrintshop /><span>Распечатать</span></button>
            <button type="button" onClick={createPdf}><MdOutlinePictureAsPdf /><span>Скачать PDF</span></button>
          </div>
          <button
            type="button"
            onClick={() => {
              const isConfirmed = window.confirm("Вы уверены, что хотите закрыть текущий расчёт?");
              if (isConfirmed) {
                window.location.reload(); // Перезагрузить страницу
              }
            }}
          >
            Новый расчёт
          </button>
        </div>
      </div>

      <div className="calculation__detail">
        <h2 className="calculation__title">Детали изделия:</h2>

        {items?.details?.map((item, index) => {

          return (
            <div className="calculation__item" key={index}>
              <div className="calculation__item_top top">
                <div className="w270">
                  <span>Название</span>
                  <Select className="calculation__select_new calculation__select_full"
                  name="name"
                  value={item.name}
                  onChange={(e) => {handleInputChangeItems(e, 'details', index, 'name')}}
                  classNamePrefix="react-select" options={options_product}
                  formatOptionLabel={color => (
                    <div className="color_option">
                      <div className="span">{color.label}</div>
                    </div>
                  )}/>
                </div>

                <div className="w145">
                  <span>Толщина (мм)</span>
                  <Select className="calculation__select_new calculation__select_full"
                  name="thickness" onChange={(e) => {handleInputChangeItems(e, 'details', index, 'thickness')}}
                  value={item.thickness}
                  classNamePrefix="react-select" options={options_width} formatOptionLabel={color => (
                    <div className="color_option">
                      <div className="span">{color.label}</div>
                    </div>
                  )}/>
                </div>

                <div className="w350">
                  <span>Цвет</span>
                  <Select className="calculation__select_new" name="color" onChange={(e) => {handleInputChangeItems(e, 'details', index, 'color')}}
                  classNamePrefix="react-select"
                  components={{
                      DropdownIndicator: (props) => (
                          <CustomDropdownIndicator {...{ ...props, style: 'color', type: 'details', name: 'color', index: index }} />
                      ),
                  }}
                  options={optionsColors}
                  value={item.color}
                  formatOptionLabel={color => (
                    <div className="color_option">
                      <div className="span">{color.name}<span>{color.label}</span></div>
                      <img src={color.image} />
                    </div>
                  )}/>
                </div>

                <label className="w135">
                  <span>Длинна (мм)</span>
                  <NumericFormat
                    thousandSeparator=" "
                    onChange={(e) => {handleInputChangeItems(e, 'details', index)}}
                    allowLeadingZeros
                    autoComplete="off"
                    name="length"
                    placeholder="0"
                  />
                </label>

                <label className="w135">
                  <span>Ширина (мм)</span>
                  <NumericFormat
                    thousandSeparator=" "
                    onChange={(e) => {handleInputChangeItems(e, 'details', index)}}
                    allowLeadingZeros
                    autoComplete="off"
                    name="width"
                    placeholder="0"
                  />
                </label>

                <label className="w100">
                  <span>Количество</span>
                  <NumericFormat
                    thousandSeparator=" "
                    value={item.count}
                    onChange={(e) => {handleInputChangeItems(e, 'details', index)}}
                    allowLeadingZeros
                    autoComplete="off"
                    name="count"
                    placeholder="0"
                  />
                </label>
              </div>

              <div className="calculation__item_bottom">
                <p>
                  <i>Цена за метр:</i>
                  <NumericFormat
                    value={item.price_metr}
                    allowLeadingZeros
                    thousandSeparator=" "
                    name="price_metr"
                    
                    suffix=" ₽"
                    displayType="text"
                  />
                </p>
                <p>
                  <i>Площадь:</i>
                  <NumericFormat
                    value={item.area}
                    allowLeadingZeros
                    thousandSeparator=" "
                    
                    name="area"
                    suffix=" м²"
                    displayType="text"
                  />
                </p>
                <p>
                  <i>Итоговая цена:</i>
                  <NumericFormat
                    value={item.price}
                    allowLeadingZeros
                    thousandSeparator=" "
                    
                    name="total_price"
                    suffix=" ₽"
                    displayType="text"
                  />
                </p>
                <p className="red_noty">Возможность изготовления заказа менее 1.5м<sup>2</sup> уточняйте отдельно</p>
              </div>
                {items?.details.length > 1 ?
                  <button
                    className="calculation__item_remove"
                    onClick={(e) => {
                        e.preventDefault(); // Предотвращает отправку формы
                        handleRemoveElement('details', index);
                    }}
                  >
                    <span>Убрать элемент</span>
                    <MdAdd />
                  </button>
                :'' }
                <button
                  className="calculation__item_add"
                    onClick={(e) => {
                        e.preventDefault(); // Предотвращает отправку формы
                        handleDublicateElement('details');
                    }}
                >
                  <span>Добавить еще элемент</span>
                  <MdAdd />
                </button>
            </div>
          );
        })}

        <div className="calculation__item_info">
          <p>
            <i>Общая площадь всех элементов</i>
            <NumericFormat
              value={items.total.details_area}
              allowLeadingZeros
              thousandSeparator=" "
              
              suffix=" м²"
              displayType="text"
            />
          </p>

          <p>
            <i>Итоговая цена всех элементов</i>
            <NumericFormat
              value={items.total.details_price}
              allowLeadingZeros
              thousandSeparator=" "
              
              suffix=" ₽"
              displayType="text"
            />
          </p>
        </div>
      </div>

      <div className="calculation__detail">
        <h2 className="calculation__title v2">Выбрать бортик:</h2>
        {items?.borders?.map((item, index) => {
          return (
            <div className="calculation__item" key={index}>
              <div className="calculation__item_top">
                <div className="w270">
                  <span>Выбрать модель</span>
                  <Select className="calculation__select_new calculation__select_full" name="model"
                  onChange={(e) => {handleInputChangeItems(e, 'borders', index, 'model')}}
                  classNamePrefix="react-select"
                  components={{
                      DropdownIndicator: (props) => (
                          <CustomDropdownIndicator {...{ ...props, style: 'color', type: 'borders', name: 'model', index:index }} />
                      ),
                  }}
                  options={options_border}
                  value={item.model}
                  formatOptionLabel={border => (
                    <div className="color_option">
                      <div className="span">{border.label}</div>
                    </div>
                  )}/>
                </div>

                <label className="w135">
                  <span>Высота (мм)</span>
                  <Select className="calculation__select_new calculation__select_full" name="thickness" onChange={(e) => {handleInputChangeItems(e, 'borders', index, 'thickness')}} classNamePrefix="react-select" options={options_thickness} formatOptionLabel={border => (
                    <div className="color_option">
                      <div className="span">{border.label}</div>
                    </div>
                  )}/>

                </label>

                <label className="w135">
                  <span>Длинна (мм)</span>
                  <NumericFormat
                    thousandSeparator=" "
                    allowLeadingZeros
                    name="length"
                    autoComplete="off"
                    onChange={(e) => {handleInputChangeItems(e, 'borders', index)}}
                    placeholder="0"
                  />
                </label>
              </div>

              <div className="calculation__item_bottom">
                <p>
                  <i>Общая длинна:</i>
                  <NumericFormat
                    value={parseFloat(String(item.length).replace(' ', '')) / 1000}
                    allowLeadingZeros
                    thousandSeparator=" "
                    
                    suffix=" пог. м"
                    displayType="text"
                  />
                </p>
                <p>
                  <i>Цена за пог. м:</i>
                  <NumericFormat
                    value={item.price_metr}
                    allowLeadingZeros
                    thousandSeparator=" "
                    suffix=" ₽"
                    displayType="text"
                  />
                </p>
                <p>
                  <i>Итоговая цена:</i>
                  <NumericFormat
                    value={item.price}
                    allowLeadingZeros
                    thousandSeparator=" "
                    
                    suffix=" ₽"
                    displayType="text"
                  />
                </p>
              </div>

                {items?.borders.length > 1 ?
                  <button
                    className="calculation__item_remove"
                    onClick={(e) => {
                        e.preventDefault(); // Предотвращает отправку формы
                        handleRemoveElement('borders', index);
                    }}
                  >
                    <span>Убрать элемент</span>
                    <MdAdd />
                  </button>
                :'' }
                <button
                  className="calculation__item_add"
                  onClick={(e) => {
                      e.preventDefault(); // Предотвращает отправку формы
                      handleDublicateElement('borders');
                  }}
                >
                  <span>Добавить еще элемент</span>
                  <MdAdd />
                </button>
            </div>
          );
        })}


        <div className="calculation__item_info">
          <p>
            <i>Общая длинна</i>
            <NumericFormat
              value={items.total.borders_area }
              allowLeadingZeros
              thousandSeparator=" "
              
              suffix=" пог. м"
              displayType="text"
            />
          </p>

          <p>
            <i>Итоговая цена всех элементов</i>
            <NumericFormat
              value={items.total.borders_price}
              allowLeadingZeros
              thousandSeparator=" "
              
              suffix=" ₽"
              displayType="text"
            />
          </p>
        </div>
      </div>

      <div className="calculation__detail">
        <h2 className="calculation__title v2">Выбрать кромку:</h2>

        {items?.edge?.map((item, index) => {
          return (
            <div className="calculation__item"  key={index}>
              <div className="calculation__item_top">
                <div className="w270">
                  <span>Выбрать модель</span>
                  <Select className="calculation__select_new calculation__select_full"
                  name="model"
                  onChange={(e) => {handleInputChangeItems(e, 'edge', index, 'model')}}
                  classNamePrefix="react-select"
                  components={{
                      DropdownIndicator: (props) => (
                          <CustomDropdownIndicator {...{ ...props, style: 'color', type: 'edge', name: 'model', index: index}} />
                      ),
                  }}
                  options={options_edge}
                  value={item.model}
                  formatOptionLabel={edge => (
                    <div className="color_option">
                      <div className="span">{edge.label}</div>
                    </div>
                  )}/>
                </div>

                <label className="w135">
                  <span>Длинна (мм)</span>
                  <NumericFormat
                    name="length"
                    onChange={(e) => {handleInputChangeItems(e, 'edge', index)}}
                    thousandSeparator=" "
                    allowLeadingZeros
                    autoComplete="off"
                    name="length"
                    placeholder="0"
                  />
                </label>

              </div>

              <div className="calculation__item_bottom">
                <p>
                  <i>Общая длинна: </i>
                  <NumericFormat
                    value={parseFloat(String(item.length).replace(' ', '')) / 1000}
                    allowLeadingZeros
                    thousandSeparator=" "
                    
                    suffix=" пог. м"
                    displayType="text"
                  />
                </p>
                <p>
                  <i>Цена за пог. м:</i>
                  <NumericFormat
                    value={item.price_metr}
                    allowLeadingZeros
                    thousandSeparator=" "
                    suffix=" ₽"
                    displayType="text"
                  />
                </p>
                <p>
                  <i>Итоговая цена:</i>
                  <NumericFormat
                    value={item.price}
                    allowLeadingZeros
                    thousandSeparator=" "
                    
                    suffix=" ₽"
                    displayType="text"
                  />
                </p>
              </div>

                {items?.edge.length > 1 ?
                  <button
                    className="calculation__item_remove"
                    onClick={(e) => {
                        e.preventDefault(); // Предотвращает отправку формы
                        handleRemoveElement('edge', index);
                    }}
                  >
                    <span>Убрать элемент</span>
                    <MdAdd />
                  </button>
                :'' }
                <button
                  className="calculation__item_add"
                  onClick={(e) => {
                      e.preventDefault(); // Предотвращает отправку формы
                      handleDublicateElement('edge');
                  }}
                >
                  <span>Добавить еще элемент</span>
                  <MdAdd />
                </button>
            </div>
          );
        })}

        <div className="calculation__item_info">
          <p>
            <i>Общая длин
            на</i>
            <NumericFormat
              value={items.total.edge_area}
              allowLeadingZeros
              thousandSeparator=" "
              
              suffix=" пог. м"
              displayType="text"
            />
          </p>

          <p>
            <i>Итоговая цена всех элементов</i>
            <NumericFormat
              value={items.total.edge_price}
              allowLeadingZeros
              thousandSeparator=" "
              
              suffix=" ₽"
              displayType="text"
            />
          </p>
        </div>
      </div>

      <div className="calculation__detail">
        <h2 className="calculation__title v2">Выбрать мойку или раковину:</h2>
        {items?.washing?.map((item, index) => {
          return (
            <div className="calculation__item"  key={index}>
              <div className="calculation__item_top">
                <div className="w270">
                  <span>Категория</span>
                  <Select className="calculation__select_new calculation__select_full" onChange={(e) => {handleInputChangeItems(e, 'washing', index, 'category')}} name="category" classNamePrefix="react-select"  options={[{value: 'sink', label: 'Раковина'}, {value: 'washing', label: 'Мойка'}]} formatOptionLabel={washing => (
                    <div className="color_option">
                      <div className="span">{washing.label}</div>
                    </div>
                  )}/>
                </div>

                <div className="w270">
                  <span>Выбрать модель</span>
                  <Select className="calculation__select_new calculation__select_full"
                  onChange={(e) => {handleInputChangeItems(e, 'washing', index, 'model')}}
                  components={{
                      DropdownIndicator: (props) => (
                          <CustomDropdownIndicator {...{ ...props, style: 'color', type: 'washing', name: 'model', index: index }} />
                      ),
                  }}
                  name="model"
                  value={item.model}
                  classNamePrefix="react-select"
                  options={optionsWashing} formatOptionLabel={washing => (
                    <div className="color_option">
                      <div className="span">{washing.label}</div>
                    </div>
                  )}/>
                </div>

                <div className="w350">
                  <span>Цвет</span>
                  <Select className="calculation__select_new"
                  name="color"
                  onChange={(e) => {handleInputChangeItems(e, 'washing', index, 'color')}}
                  classNamePrefix="react-select"
                  components={{
                      DropdownIndicator: (props) => (
                          <CustomDropdownIndicator {...{ ...props, style: 'color', type: 'washing', name: 'color', index: index }} />
                      ),
                  }}
                  value={item.color}
                  options={optionsColors}
                  formatOptionLabel={color => (
                    <div className="color_option">
                      <div className="span">{color.name}<span>{color.label}</span></div>
                      <img src={color.image} />
                    </div>
                  )}/>
                </div>

                <label className="w100">
                  <span>Количество</span>
                  <NumericFormat
                    thousandSeparator=" "
                    allowLeadingZeros
                    value={item.count}
                    onChange={(e) => {handleInputChangeItems(e, 'washing', index)}}
                    name="count"
                    autoComplete="off"
                    placeholder="0"
                  />
                </label>
              </div>

              <div className="calculation__item_bottom">
                <p>
                  <i>Общое количество:</i>
                  <NumericFormat
                    value={item.count}
                    allowLeadingZeros
                    thousandSeparator=" "
                    
                    suffix=""
                    displayType="text"
                  />
                </p>
                <p>
                  <i>Итоговая цена:</i>
                  <NumericFormat
                    value={item.price}
                    allowLeadingZeros
                    thousandSeparator=" "
                    
                    suffix=" ₽"
                    displayType="text"
                  />
                </p>
              </div>

                {items?.washing.length > 1 ?
                  <button
                    className="calculation__item_remove"
                    onClick={(e) => {
                        e.preventDefault(); // Предотвращает отправку формы
                        handleRemoveElement('washing', index);
                    }}
                  >
                    <span>Убрать элемент</span>
                    <MdAdd />
                  </button>
                :'' }
                <button
                  className="calculation__item_add"
                  onClick={(e) => {
                      e.preventDefault(); // Предотвращает отправку формы
                      handleDublicateElement('washing');
                  }}

                >
                  <span>Добавить еще элемент</span>
                  <MdAdd />
                </button>
            </div>
          );
        })}
      </div>

      <div className="calculation__detail">
        <h2 className="calculation__title v2">Дополнительные опции:</h2>
        <div className="calculation__additional">
          <button type="button" onClick={() => openModal(options_options, 'delivery', 'options')}>Добавить дополнительные опции</button>

          {optionsCount === 0 ? (
            <p>
              <img src="/icons/warning.svg" alt="" />
              <span>
                В этом блоке Вы можете добавить дополнительные опции для изделия:
                вырезы для моек, варочных панелей, дополнительные элементы и прочее.
              </span>
            </p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Название</th>
                  <th>Ед. изм.</th>
                  <th>Кол.</th>
                  <th>Цена</th>
                  <th>Итого</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {extras['options'].map((option, index) => (
                  <tr key={index}>
                    <td className="name">{option.label}</td>
                    <td>{option.description ? option.description : 'шт.'}</td>
                    <td>
                        <NumericFormat
                            thousandSeparator=" "
                            allowLeadingZeros
                            value={option ? option.count : `0`}
                            name="count"
                            placeholder="0"
                            onChange={(e) => handleQuantityChange('options', index, e.target.value)}
                        />
                    </td>
                    <td>
                          <NumericFormat
                              value={option.price}
                              allowLeadingZeros
                              thousandSeparator=" "
                              name="price_metr"
                              suffix=" ₽"
                              displayType="text"
                          />
                    </td>
                    <td>
                          <NumericFormat
                              value={option.price * option.count}
                              allowLeadingZeros
                              thousandSeparator=" "
                              name="price_metr"
                              suffix=" ₽"
                              displayType="text"
                          />
                    </td>
                    <td>
                      <button className="remove" type="button" onClick={() => handleRemoveOption('options', index)}>
                        <MdAdd />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      <div className="calculation__detail">
        <h2 className="calculation__title v2">Установка и доставка:</h2>

        <div className="calculation__additional">
          <button type="button" onClick={() => openModal(options_install, 'delivery', 'install')}>Добавить дополнительные опции</button>
          {installCount === 0 ? (
          <p>
            <img src="/icons/warning.svg" alt="" />
            <span>
              В этом блоке Вы можете добавить опции, связанные с установкой и
              доставкой изделия.
            </span>
          </p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Название</th>
                  <th>Ед. изм.</th>
                  <th>Кол.</th>
                  <th>Цена</th>
                  <th>Итого</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {extras['install'].map((install, index) => (
                  <tr key={index}>
                    <td className="name">{install.label}</td>
                    <td>{install.description ? install.description : 'шт.'}</td>
                    <td>
                        <NumericFormat
                            thousandSeparator=" "
                            allowLeadingZeros
                            value={install ? install.count : `0`}
                            name="count"
                            placeholder="0"
                            onChange={(e) => handleQuantityChange('install', index, e.target.value)}
                        />
                    </td>
                    <td>
                          <NumericFormat
                              value={install.price}
                              allowLeadingZeros
                              thousandSeparator=" "
                              name="price_metr"
                              suffix=" ₽"
                              displayType="text"
                          />
                    </td>
                    <td>
                          <NumericFormat
                              value={install.price * install.count}
                              allowLeadingZeros
                              thousandSeparator=" "
                              name="price_metr"
                              suffix=" ₽"
                              displayType="text"
                          />
                    </td>
                    <td>
                      <button className="remove" type="button" onClick={() => handleRemoveOption('install', index)}>
                        <MdAdd />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      <div className="calculation__total">
        <p>
          <i>Итоговая цена:</i>
          <NumericFormat
            value={totalPrice}
            allowLeadingZeros
            thousandSeparator=" "
            
            suffix=" ₽"
            displayType="text"
          />
        </p>

        <div>
          <button type="button" onClick={handleSendToWork}>
            <span>Отправить в работу</span>
          </button>

          <button>
            <span>Сохранить</span>
          </button>
        </div>
        <button type="button" onClick={handlePrint}><MdLocalPrintshop /><span>Распечатать</span></button>
        <button type="button" onClick={createPdf}><MdOutlinePictureAsPdf /><span>Скачать PDF</span></button>
          <button
            type="button"
            onClick={() => {
              const isConfirmed = window.confirm("Вы уверены, что хотите закрыть текущий расчёт?");
              if (isConfirmed) {
                window.location.reload(); // Перезагрузить страницу
              }
            }}
          >
            Новый расчёт
          </button>
      </div>

      {isModalOpen && <Modal isOpen={isModalOpen} onClose={closeModal} items={modalData.items} name={modalData.name} value={modalData.value} index={modalData.index} extras={extras} type={modalData.type} style={modalData.style} onSave={handleModalSave} />}

      {lightboxOpen && (
        <Lightbox
          small={selectedImage} // Миниатюра изображения
          large={selectedImage} // Большое изображение для модального окна
          alt={selectedImageTitle} // Альтернативный текст
          onClose={handleLightboxClose} // Закрытие модального окна
        />
      )}

        <File
            isOpen={isPrintModalOpen}
            onClose={() => setIsPrintModalOpen(false)}
            onPrint={handlePrint}
            onCreatePdf={createPdf}
            checkbox="false"
        />

      </form>
    </div>



  );
};
