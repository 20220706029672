// PrintTable.jsx
import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { PatternFormat, NumericFormat } from "react-number-format";
import './PrintStyles.css'; // Импортируем стили для печати
import { useUpdateCalculatorMutation, useGetCalculatorByIdQuery } from '../../context/service/calculator.service'; 

export const PrintTable = ({ data }) => {

    const color = JSON.parse(data.color);
    const material = JSON.parse(data.material);
    const items = JSON.parse(data.items);
    const extras = JSON.parse(data.extras);
    const formattedDate = new Date(data.date).toLocaleDateString('ru-RU', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    const Plus = data.markup - data.discount;
    const markupFactor = Plus ? 1 + Plus / 100 : 1;
    return (

        <div className="table-container">
            <header className="print-header">
                <img src="/icons/login_bg_icon.svg" alt="Логотип"  className="logo" />
                
                <h1>ЗАКАЗ №{data.id}</h1>
            </header>
            <div className="table">
                <table className="border_top border_bottom">
                    <tbody>
                        <tr>
                            <td className="image_info">
                                <div className="image">
                                    <img src={color.image} alt={color.name} />
                                </div>
                                <div className="info">
                                    <p>Основной материала: </p>
                                    <strong>{material.name}</strong>
                                    <h3>{color.artiarticle_number} {color.name}</h3>
                                </div>
                            </td>
                            <td>
                                <p>Дата заказа: </p>
                                <strong>{formattedDate}</strong>
                                <p>Имя клиента: </p>
                                <strong>{data.fullname}</strong>
                            </td>
                            <td>
                                <p>Номер телефона: </p>
                                <strong>{data.phone}</strong>
                                <p>E-mail: </p>
                                <strong>{data.email}</strong>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

{items?.details && items.details[0].length !== 0 && items.details[0].count !== 0 ? (
    <>
            <h2>ЭЛЕМЕНТЫ ИЗДЕЛИЯ</h2>
            <div className="table">
                <table>
                    <thead>
                        <tr>
                            <th>Название элемента</th>
                            <th>Толщина</th>
                            <th>Цвет</th>
                            <th>Длина</th>
                            <th>Ширина</th>
                            <th>Количество</th>
                        </tr>
                    </thead>
                    <tbody>
                    {items?.details?.map((item, index) => {

                        return (
                        <React.Fragment key={index}>
                            <tr>
                                <td>{item.name.label}</td>
                                <td>{item.thickness.label || ''} мм</td>
                                <td>{item.color.label} {item.color.name}</td>
                                <td>{item.length} мм</td>
                                <td>{item.width} мм</td>
                                <td>{item.count}</td>
                            </tr>
                            <tr className="price">
                                <td colSpan="2">Цена этого элемента:
                                    <NumericFormat
                                    value={item.price}
                                    allowLeadingZeros
                                    thousandSeparator=" "
                                    suffix=" ₽"
                                    displayType="text"
                                    />
                                </td>
                                <td>Цена за м2:
                                    <NumericFormat
                                    value={item.price_metr}
                                    allowLeadingZeros
                                    thousandSeparator=" "
                                    suffix=" ₽"
                                    displayType="text"
                                    />
                                </td>
                                <td colSpan="2">Площадь:
                                    <NumericFormat
                                    value={item.area}
                                    allowLeadingZeros
                                    thousandSeparator=" "
                                    suffix=" м2"
                                    displayType="text"
                                    />
                                </td>
                            </tr>
                        </React.Fragment>
                        );
                    })} 
                    </tbody>
                </table>
            </div>
</>
) : (
    '' // Сообщение, если данных нет
)}

{items?.borders && items.borders[0].length !== 0 || items?.edge && items.edge[0].length !== 0 ? (
    <>
            <h2>Кромки и бортики</h2>
            <div className="table">
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Модель кромки / бортика </th>
                            <th>Высота</th>
                            <th>Длина</th>

                        </tr>
                    </thead>
                    <tbody>
                    {items?.borders && items.borders[0].length !== 0 ? (
                        <>
                            {items?.borders?.map((item, index) => {
                                const modelImage = item.model?.image || ''; // Устанавливаем значение по умолчанию
                                const modelLabel = item.model?.label || 'Не указано'; // Устанавливаем значение по умолчанию
                                const thicknessLabel = item.thickness?.label+' мм' || ''; // Устанавливаем значение по умолчанию
                                return (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td rowSpan="2"><img src={item.model.image} alt={item.model.label} /></td>
                                        <td>{item.model.label}</td>
                                        <td>{thicknessLabel}</td>
                                        <td>{item.length} мм</td>
                                    </tr>
                                    <tr class="price">
                                        <td class="left_padding" colSpan="2">Цена этого бортика:
                                            <NumericFormat
                                            value={item.price}
                                            allowLeadingZeros
                                            thousandSeparator=" "
                                            suffix=" ₽"
                                            displayType="text"
                                            />
                                        </td>
                                        <td>
                                            Цена за м.п.: 990 р.
                                        </td>
                                    </tr>
                                </React.Fragment>
                                );
                            })}
                        </>
                    ) : (
                        '' // Сообщение, если данных нет
                    )}
                    {items?.edge && items.edge[0].length !== 0 ? (
                        <>
                            {items?.edge?.map((item, index) => {
                                const modelImage = item.model?.image || ''; // Устанавливаем значение по умолчанию
                                const modelLabel = item.model?.label || 'Не указано'; // Устанавливаем значение по умолчанию
                                const thicknessLabel = item.thickness ? `${item.thickness.label} мм` : ''; 
                                return (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td rowSpan="2"><img src={item.model.image} alt={item.model.label} /></td>
                                        <td>{item.model.label}</td>
                                        <td>{thicknessLabel}</td>
                                        <td>{item.length} мм</td>
                                    </tr>
                                    <tr class="price">
                                        <td class="left_padding" colSpan="2">Цена этого бортика:
                                            <NumericFormat
                                            value={item.price}
                                            allowLeadingZeros
                                            thousandSeparator=" "
                                            suffix=" ₽"
                                            displayType="text"
                                            />
                                        </td>
                                        <td>
                                            Цена за м.п.: 990 р.
                                        </td>
                                    </tr>
                                </React.Fragment>
                                );
                            })}
                        </>
                    ) : (
                        '' // Сообщение, если данных нет
                    )}
                    </tbody>
                </table>
            </div>
</>
) : (
    '' // Сообщение, если данных нет
)}


{items?.washing && items?.washing[0].count !== 0 && items?.washing[0].model !== '' ? (
<>
    <h2>Раковины и мойки</h2>
    <div className="table">
        <table>
            <thead>
                <tr>
                    <th>Модель</th>
                    <th>Цвет</th>
                    <th>Количество</th>
                    <th>Стоимость</th>
                </tr>
            </thead>
            <tbody>
                {items?.washing?.map((item, index) => {
                    return (
                    <React.Fragment key={index}>
                        <tr>
                            <td>{ item.category.label} { item.model.label}</td>
                            <td>{item.color.label}</td>
                            <td>{item.count}</td>
                            <td>
                                <NumericFormat
                                value={item.price}
                                allowLeadingZeros
                                thousandSeparator=" "
                                suffix=" ₽"
                                displayType="text"
                                />
                            </td>
                        </tr>
                    </React.Fragment>
                    )
                })}
            </tbody>
        </table>
    </div>
</>
) : (
    '' // Сообщение, если данных нет
)}

{extras?.options && extras.options.length > 0 || extras?.install && extras.install.length > 0 ? (
    <>
        {extras?.options && extras.options.length > 0 ? (
            <>
                <h2>Дополнительные опции</h2>
                <div className="table">
                    <table>
                        <thead>
                            <tr>
                                <th>Наименование доп. опции</th>
                                <th>Ед. измерения</th>
                                <th>Цена</th>
                                <th>Кол-во</th>
                                <th>Стоимость</th>
                            </tr>
                        </thead>
                        <tbody>
                            {extras.options.map((item) => (
                                <React.Fragment key={item.id}>
                                    <tr>
                                        <td>{item.label}</td>
                                        <td>{item.description || 'шт.'}</td>
                                        <td>
                                            <NumericFormat
                                                value={item.price}
                                                allowLeadingZeros
                                                thousandSeparator=" "
                                                suffix=" ₽"
                                                displayType="text"
                                            />
                                        </td>
                                        <td>{item.count}</td>
                                        <td>
                                            <NumericFormat
                                                value={item.price * item.count}
                                                allowLeadingZeros
                                                thousandSeparator=" "
                                                suffix=" ₽"
                                                displayType="text"
                                            />
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>
        ) : null}

        {extras?.install && extras.install.length > 0 ? (
            <>
                <h2>УСТАНОВКА И ДОСТАВКА</h2>
                <div className="table">
                    <table>
                        <thead>
                            <tr>
                                <th>Наименование доп. опции</th>
                                <th>Ед. измерения</th>
                                <th>Цена</th>
                                <th>Кол-во</th>
                                <th>Стоимость</th>
                            </tr>
                        </thead>
                        <tbody>
                            {extras.install.map((item) => (
                                <React.Fragment key={item.id}>
                                    <tr>
                                        <td>{item.label}</td>
                                        <td>{item.description || 'шт.'}</td>
                                        <td>
                                            <NumericFormat
                                                value={item.price}
                                                allowLeadingZeros
                                                thousandSeparator=" "
                                                suffix=" ₽"
                                                displayType="text"
                                            />
                                        </td>
                                        <td>{item.count}</td>
                                        <td>
                                            <NumericFormat
                                                value={item.price * item.count}
                                                allowLeadingZeros
                                                thousandSeparator=" "
                                                suffix=" ₽"
                                                displayType="text"
                                            />
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>
        ) : null}
    </>
) : (
    '' // Сообщение, если данных нет
)}
            <h4>Всего стоимость заказа:</h4>
            <h1>                                
                <NumericFormat
                value={data.material_price + data.extras_price}
                allowLeadingZeros
                thousandSeparator=" "
                suffix=" ₽"
                displayType="text"
                />
            </h1>
        </div>
    );
};

export default PrintTable;