// PrintTable.jsx
import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { PatternFormat, NumericFormat } from "react-number-format";
import './PrintStyles.css'; // Импортируем стили для печати
import { useUpdateCalculatorMutation, useGetCalculatorByIdQuery } from '../../context/service/calculator.service'; 

export const PrintTable = ({ data, info, items = false, color, material, extras, markup, discount, price }) => {
    // Declare variables outside of the if/else blocks
    let parsedColor, parsedMaterial, parsedItems, parsedExtras, formattedDate, parsedMarkup, parsedDiscount, id, logo;
    let totalPrice = 0;
    if (items !== false) {
        parsedColor = color;
        parsedMaterial = material;
        parsedItems = items;
        parsedExtras = extras;
        formattedDate = '';
        parsedMarkup = markup;
        parsedDiscount = discount;
        id = '';
        logo = '/icons/login_bg_icon.svg';
        totalPrice = price;
    } else {
        parsedColor = JSON.parse(data.color);
        parsedMaterial = JSON.parse(data.material);
        parsedItems = JSON.parse(data.items);
        parsedExtras = JSON.parse(data.extras);
        formattedDate = new Date(data.date).toLocaleDateString('ru-RU', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
        parsedMarkup =  data.markup;
        parsedDiscount =  data.discount;
        id = data?.id;
        logo = data?.logo;
        totalPrice = data.material_price + data.extras_price;
    }

    const Plus = (parsedMarkup || 0) - parsedDiscount;
    const markupFactor = Plus ? 1 + Plus / 100 : 1;
    totalPrice = parseFloat((totalPrice).toFixed(0));

    return (
        <div className="table-container">
            <header className="print-header">
                {logo && info === false ? (
                    <img className="logo" src={logo} alt="" />
                ) : (
                    <img src="/icons/login_bg_icon.svg" alt="logo" className="logo" />
                )}
                <h1>ЗАКАЗ №{id}</h1>
            </header>
            <div className="table">
                <table className="border_top border_bottom">
                    <tbody>
                        <tr>
                            <td className="image_info">
                                <div className="image">
                                    <img src={parsedColor.image} alt={parsedColor.name} />
                                </div>
                                <div className="info">
                                    <p>Основной материала: </p>
                                    <strong>{parsedMaterial.name}</strong>
                                    <h3>{parsedColor.artiarticle_number} {parsedColor.name}</h3>
                                </div>
                            </td>
                            <td>
                                <p>Дата заказа: </p>
                                <strong>{info === false ? formattedDate : '\u00A0'}</strong>
                                <p>Имя клиента: </p>
                                <strong>{info === false ? data.fullname : '\u00A0'}</strong>
                            </td>
                            <td>
                                <p>Номер телефона: </p>
                                <strong>{info === false ? data.phone : '\u00A0'}</strong>
                                <p>E-mail: </p>
                                <strong>{info === false ? data.email : '\u00A0'}</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* Детали изделия */}
            {parsedItems?.details && parsedItems.details[0].length !== 0 && parsedItems.details[0].count !== 0 ? (
                <>
                    <h2>ЭЛЕМЕНТЫ ИЗДЕЛИЯ</h2>
                    <div className="table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Название элемента</th>
                                    <th>Толщина</th>
                                    <th>Цвет</th>
                                    <th>Длина</th>
                                    <th>Ширина</th>
                                    <th>Количество</th>
                                </tr>
                            </thead>
                            <tbody>
                                {parsedItems?.details?.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td>{item.name.label}</td>
                                            <td>{item.thickness.label || ''} мм</td>
                                            <td>{item.color.label} {item.color.name}</td>
                                            <td>{item.length} мм</td>
                                            <td>{item.width} мм</td>
                                            <td>{item.count}</td>
                                        </tr>
                                        <tr className="price">
                                            <td colSpan="2">Цена этого элемента:
                                                <NumericFormat
                                                    value={item.price}
                                                    allowLeadingZeros
                                                    thousandSeparator=" "
                                                    suffix=" ₽"
                                                    displayType="text"
                                                />
                                            </td>
                                            <td>Цена за м2:
                                                <NumericFormat
                                                    value={item.price_metr}
                                                    allowLeadingZeros
                                                    thousandSeparator=" "
                                                    suffix=" ₽"
                                                    displayType="text"
                                                />
                                            </td>
                                            <td colSpan="2">Площадь:
                                                <NumericFormat
                                                    value={item.area}
                                                    allowLeadingZeros
                                                    thousandSeparator=" "
                                                    suffix=" м2"
                                                    displayType="text"
                                                />
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            ) : null}

            {/* Кромки и бортики */}
            {parsedItems?.borders && parsedItems.borders[0].length !== 0 || parsedItems?.edge && parsedItems.edge[0].length !== 0 ? (
                <>
                    <h2>Кромки и бортики</h2>
                    <div className="table">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Модель кромки / бортика</th>
                                    <th>Высота</th>
                                    <th>Длина</th>
                                </tr>
                            </thead>
                            <tbody>
                                {parsedItems?.borders && parsedItems.borders[0].length !== 0 ? (
                                    <>
                                        {parsedItems?.borders?.map((item, index) => (
                                            <React.Fragment key={index}>
                                                <tr>
                                                    <td rowSpan="2"><img src={item.model.image} alt={item.model.label} /></td>
                                                    <td>{item.model.label}</td>
                                                    <td>{item.thickness?.label || ''} мм</td>
                                                    <td>{item.length} мм</td>
                                                </tr>
                                                <tr className="price">
                                                    <td className="left_padding" colSpan="2">Цена этого бортика:
                                                        <NumericFormat
                                                            value={item.price}
                                                            allowLeadingZeros
                                                            thousandSeparator=" "
                                                            suffix=" ₽"
                                                            displayType="text"
                                                        />
                                                    </td>
                                                    <td>Цена за м.п.: {Math.round(((item.model?.price || 0) + (item.thickness?.price || 0)) * markupFactor)} ₽</td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </>
                                ) : null}
                                {parsedItems?.edge && parsedItems.edge[0].length !== 0 ? (
                                    <>
                                        {parsedItems?.edge?.map((item, index) => (
                                            <React.Fragment key={index}>
                                                <tr>
                                                    <td rowSpan="2"><img src={item.model.image} alt={item.model.label} /></td>
                                                    <td>{item.model.label}</td>
                                                    <td>{item.thickness?.label || ''} мм</td>
                                                    <td>{item.length} мм</td>
                                                </tr>
                                                <tr className="price">
                                                    <td className="left_padding" colSpan="2">Цена этого бортика:
                                                        <NumericFormat
                                                            value={item.price}
                                                            allowLeadingZeros
                                                            thousandSeparator=" "
                                                            suffix=" ₽"
                                                            displayType="text"
                                                        />
                                                    </td>
                                                    <td>Цена за м.п.: {Math.round(((item.model?.price || 0) + (item.thickness?.price || 0)) * markupFactor)} ₽</td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </>
                                ) : null}
                            </tbody>
                        </table>
                    </div>
                </>
            ) : null}

            {/* Раковины и мойки */}
            {parsedItems?.washing && parsedItems?.washing[0].count !== 0 && parsedItems?.washing[0].model !== '' ? (
                <>
                    <h2>Раковины и мойки</h2>
                    <div className="table">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Модель</th>
                                    <th>Цвет</th>
                                    <th>Количество</th>
                                    <th>Стоимость</th>
                                </tr>
                            </thead>
                            <tbody>
                                {parsedItems?.washing?.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td rowSpan="2"><img src={item.model.image} alt={item.model.label} /></td>
                                            <td>{item.category.label} {item.model.label}</td>
                                            <td>{item.color.label}</td>
                                            <td>{item.count}</td>
                                            <td>
                                                <NumericFormat
                                                    value={item.price}
                                                    allowLeadingZeros
                                                    thousandSeparator=" "
                                                    suffix=" ₽"
                                                    displayType="text"
                                                />
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            ) : null}

            {/* Дополнительные опции */}
            {parsedExtras?.options && parsedExtras.options.length > 0 ? (
                <>
                    <h2>Дополнительные опции</h2>
                    <div className="table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Наименование доп. опции</th>
                                    <th>Ед. измерения</th>
                                    <th>Цена</th>
                                    <th>Кол-во</th>
                                    <th>Стоимость</th>
                                </tr>
                            </thead>
                            <tbody>
                                {parsedExtras.options.map((item) => (
                                    <React.Fragment key={item.id}>
                                        <tr>
                                            <td>{item.label}</td>
                                            <td>{item.description || 'шт.'}</td>
                                            <td>
                                                <NumericFormat
                                                    value={item.price}
                                                    allowLeadingZeros
                                                    thousandSeparator=" "
                                                    suffix=" ₽"
                                                    displayType="text"
                                                />
                                            </td>
                                            <td>{item.count}</td>
                                            <td>
                                                <NumericFormat
                                                    value={item.price * item.count}
                                                    allowLeadingZeros
                                                    thousandSeparator=" "
                                                    suffix=" ₽"
                                                    displayType="text"
                                                />
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            ) : null}

            {/* Установка и доставка */}
            {parsedExtras?.install && parsedExtras.install.length > 0 ? (
                <>
                    <h2>УСТАНОВКА И ДОСТАВКА</h2>
                    <div className="table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Наименование доп. опции</th>
                                    <th>Ед. измерения</th>
                                    <th>Цена</th>
                                    <th>Кол-во</th>
                                    <th>Стоимость</th>
                                </tr>
                            </thead>
                            <tbody>
                                {parsedExtras.install.map((item) => (
                                    <React.Fragment key={item.id}>
                                        <tr>
                                            <td>{item.label}</td>
                                            <td>{item.description || 'шт.'}</td>
                                            <td>
                                                <NumericFormat
                                                    value={item.price}
                                                    allowLeadingZeros
                                                    thousandSeparator=" "
                                                    suffix=" ₽"
                                                    displayType="text"
                                                />
                                            </td>
                                            <td>{item.count}</td>
                                            <td>
                                                <NumericFormat
                                                    value={item.price * item.count}
                                                    allowLeadingZeros
                                                    thousandSeparator=" "
                                                    suffix=" ₽"
                                                    displayType="text"
                                                />
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            ) : null}

            {/* Общая стоимость заказа */}
            <h4>Всего стоимость заказа:</h4>
            <h1>
                <NumericFormat
                    value={totalPrice}
                    allowLeadingZeros
                    thousandSeparator=" "
                    suffix=" ₽"
                    displayType="text"
                />
            </h1>
        </div>
    );
};

export default PrintTable;