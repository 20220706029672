//calculator.service.js
import { apiSlice } from "./api.service";

export const calculatorApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // create product service: POST - /create/product
    createCalculator: builder.mutation({
      query: (body) => ({
        url: "/create/calculators",
        method: "POST",
        body,
      }),
      invalidatesTags: ["calculator"],
    }),

    // Получение всех калькуляторов: POST /calculators/user
  getCalculatorsByArchive: builder.query({
    query: ({ page }) => {
      return `/get/calculators/archive?page=${page}`;
    },
    providesTags: ["calculator"],
  }),

    // Получение калькулятора по ID: GET /calculators/:id
    getCalculatorById: builder.query({
      query: (id) => `/get/calculator/${id}`,
      providesTags: ["calculator"],
    }),

    // Обновление калькулятора: PUT /calculators/:id
    updateCalculator: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/update/calculators/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["calculator"],
    }),

    // Обновление калькулятора: PUT /calculators/:id
    inWorkCalculator: builder.mutation({
      query: ( id ) => ({
        url: `/inwork/calculators/${id}`,
        method: "PATCH",
      }),
      invalidatesTags: ["calculator"],
    }),

    sendPaidCalculator: builder.mutation({
      query: ( id ) => ({
        url: `/sendpaid/calculators/${id}`,
        method: "PATCH",
      }),
      invalidatesTags: ["calculator"],
    }),

    isPaidCalculator: builder.mutation({
      query: ({ id, paid }) => ({
        url: `/ispaid/calculators/${id}`,
        method: "POST",
        body: { paid: paid }, // Используем 'body' для передачи данных
      }),
      invalidatesTags: ["calculator"],
    }),

    // Удаление калькулятора: DELETE /calculators/:id
    deleteCalculator: builder.mutation({
      query: (id) => ({
        url: `/delete/calculators/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["calculator"],
    }),

  }),
});

export const {
  useCreateCalculatorMutation,
  useGetCalculatorsByArchiveQuery,
  useGetCalculatorByIdQuery,
  useInWorkCalculatorMutation,
  useSendPaidCalculatorMutation,
  useIsPaidCalculatorMutation,
  useUpdateCalculatorMutation,
  useDeleteCalculatorMutation,
} = calculatorApi;
