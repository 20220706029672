import React, { useState, useEffect } from 'react';
import { PatternFormat, NumericFormat } from "react-number-format";

const Modal = ({ isOpen, onClose, items, name, value, index, extras, onSave, type, style}) => {
    const [quantities, setQuantities] = useState([]);
    const [selectedOption, setSelectedOption] = useState(value);

    useEffect(() => {
        if (style === 'delivery' && isOpen) {
            let initialQuantities = [...items]; // Начинаем с массива items

            if (type === 'options') {
                if (Array.isArray(extras['options'])) {
                    // Заменяем значения из extras['options'], если совпадают
                    initialQuantities = initialQuantities.map(item => {
                        const extra = extras['options'].find(extraItem => extraItem.value === item.value);
                        return extra ? extra : item; // Заменяем на extra, если найдено совпадение, иначе оставляем item
                    });
                }
            } else if (type === 'install') {
                if (Array.isArray(extras['install'])) {
                    // Заменяем значения из extras['install'], если совпадают
                    initialQuantities = initialQuantities.map(item => {
                        const extra = extras['install'].find(extraItem => extraItem.value === item.value);
                        return extra ? extra : item; // Заменяем на extra, если найдено совпадение, иначе оставляем item
                    });
                }
            }

            setQuantities(initialQuantities); // Устанавливаем окончательный массив
        }
    }, [isOpen, extras, items, type, style]);

    const handleOverlayClick = (e) => {
      if (e.target === e.currentTarget) {
          onClose();
      }
    };

    const handleCountChangeItems = (e, option, index) => {
        const newQuantities = [...quantities];
        const value = parseFloat(e.target.value.replace(/\s/g, '')) || 0; // Убираем пробелы и преобразуем в число

        newQuantities[index] = {
            ...option,
            count: value,
            total: value * option.price
        };
        
        setQuantities(newQuantities);
    };

    const handleSaveSelect = () => {
        if(style == 'delivery') {
            onSave(type, style, quantities); // Передаём сохранённые значения родительскому компоненту
        } else {
            onSave(type,style,name,index,selectedOption);
        }
        onClose(); // Закрываем модальное окно
    };

    const handleSelectItem = (option, index2) => {
        setSelectedOption(option);
    };

    if (!isOpen) return null; // Не рендерим ничего, если модал закрыт

    return (
        <div onClick={handleOverlayClick} className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <div className="modal-body" key="0">
                    <div className="image-list">
                        {style === 'delivery' ? (
                            <div className="notice error">
                                <div>
                                    <svg
                                        width="37"
                                        height="34"
                                        viewBox="0 0 37 34"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M20.164 26.587C20.164 26.3465 20.1167 26.1083 20.0246 25.8861C19.9326 25.6639 19.7977 25.462 19.6276 25.2919C19.4575 25.1219 19.2556 24.987 19.0334 24.8949C18.8112 24.8029 18.573 24.7555 18.3325 24.7555C18.092 24.7555 17.8538 24.8029 17.6316 24.8949C17.4094 24.987 17.2075 25.1219 17.0375 25.2919C16.8674 25.462 16.7325 25.6639 16.6404 25.8861C16.5484 26.1083 16.501 26.3465 16.501 26.587C16.5161 27.0625 16.7155 27.5134 17.0572 27.8444C17.3988 28.1755 17.8559 28.3606 18.3316 28.3606C18.8073 28.3606 19.2644 28.1755 19.606 27.8444C19.9477 27.5134 20.1471 27.0625 20.1622 26.587M19.6855 12.1898C19.638 11.8446 19.4612 11.5302 19.1907 11.3103C18.9203 11.0905 18.5764 10.9815 18.2287 11.0055C17.881 11.0295 17.5554 11.1846 17.3177 11.4395C17.08 11.6945 16.948 12.0301 16.9484 12.3787L16.9557 20.6305L16.9685 20.8175C17.016 21.1628 17.1929 21.4771 17.4633 21.697C17.7338 21.9169 18.0776 22.0258 18.4253 22.0018C18.773 21.9778 19.0987 21.8227 19.3363 21.5678C19.574 21.3129 19.706 20.9772 19.7057 20.6287L19.6984 12.375L19.6855 12.1898ZM21.9405 2.1285C20.3694 -0.7095 16.2902 -0.7095 14.7209 2.1285L0.521692 27.797C-0.999974 30.547 0.989192 33.9203 4.13336 33.9203H32.5317C35.674 33.9203 37.6632 30.547 36.1415 27.797L21.9405 2.1285ZM17.1262 3.4595C17.245 3.24436 17.4194 3.06501 17.631 2.94011C17.8427 2.81522 18.084 2.74934 18.3298 2.74934C18.5756 2.74934 18.8168 2.81522 19.0285 2.94011C19.2402 3.06501 19.4145 3.24436 19.5334 3.4595L33.7344 29.1298C33.8502 29.3393 33.9094 29.5753 33.906 29.8146C33.9027 30.0539 33.8369 30.2882 33.7152 30.4943C33.5936 30.7004 33.4202 30.8712 33.2123 30.9897C33.0044 31.1083 32.7692 31.1706 32.5299 31.1703H4.13336C3.89403 31.1706 3.65879 31.1083 3.45089 30.9897C3.24299 30.8712 3.06965 30.7004 2.94799 30.4943C2.82632 30.2882 2.76057 30.0539 2.75721 29.8146C2.75386 29.5753 2.81302 29.3393 2.92886 29.1298L17.1262 3.4595Z" fill="#0C6B60" />
                                    </svg>
                                </div>
                                <div className="text">
                                    Наименования дополнительных опций, для которых вы проставите значения в столбце <strong>Количество</strong> будут добавлены к расчёту после нажатия на кнопку <strong>Сохранить</strong> выбор
                                </div>
                            </div>
                        ) : null}

                        {style === 'color' ? (
                            <ol className={`calculation__grades ${type} ${name}`}>
                                {items.length > 0 ? (
                                    items.map((option) => (
                                        <li key={option.id} className={"calculation__grade" + (selectedOption?.value === option.value ? " active" : "")} onClick={() => handleSelectItem(option)}>
                                            <div className="block_image">
                                                <img src={option.image} alt={option.label} className="full_image" />
                                            </div>
                                            <h3>{option.label}</h3>
                                            <span>{option.artiarticle_number}</span>
                                            <p>{option.description}</p>
                                            <img src="/icons/checkbox.svg" alt="checkbox" className={"checkbox" + (selectedOption?.value === option.value ? " active" : "")} />
                                        </li>
                                    ))
                                ) : (
                                    <li>Нет доступных данных для отображения.</li>
                                )}
                            </ol>
                        ) : style === 'delivery' ? (
                            <table>
                                <thead>
                                    <tr>
                                        <td className="title">Наименование доп. опции:</td>
                                        <td>Ед. измерения:</td>
                                        <td>Кол</td>
                                        <td>Цена:</td>
                                        <td>Стоимость:</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.length > 0 ? (
                                        items.map((option, index) => (
                                            <tr className="tr_dop" key={index}>
                                                <td className="title">{option.label}</td>
                                                <td>{option.description ? option.description : 'шт.'}</td>
                                                <td>
                                                    <NumericFormat
                                                        thousandSeparator=" "
                                                        allowLeadingZeros
                                                        value={quantities[index] ? quantities[index].count : 0}
                                                        name="count"
                                                        placeholder="0"
                                                        onChange={(e) => handleCountChangeItems(e, option, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <NumericFormat
                                                        value={option.price}
                                                        allowLeadingZeros
                                                        thousandSeparator=" "
                                                        name="price_metr"
                                                        suffix=" ₽"
                                                        displayType="text"
                                                    />
                                                </td>
                                                <td>
                                                    <NumericFormat
                                                        value={quantities[index] ? quantities[index].total : 0} // Здесь лучше использовать quantities, так как именно он обновляется в handleCountChangeItems
                                                        allowLeadingZeros
                                                        thousandSeparator=" "
                                                        name="total"
                                                        suffix=" ₽"
                                                        displayType="text" // Отображение в виде текста вместо поля ввода
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5">Нет доступных данных для отображения.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        ) : (
                            <div>Ничего не найдено.</div>
                        )}

                    </div>
                </div>
                <div className="modal-footer">
                    <button className="save-button" onClick={handleSaveSelect}>
                        Сохранить выбор
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;