import React from "react";
import "./home.css";
import { enqueueSnackbar as EnSn } from "notistack";
import { useGetUsersQuery } from "../../../context/service/user.service";
import { useDeleteUserMutation } from "../../../context/service/user.service";
import { useChangeStatusMutation } from "../../../context/service/auth.service";

export const Home = () => {
  const { data = null, isLoading } = useGetUsersQuery("pending");
  const [changeStatus] = useChangeStatusMutation();
  const [deleteUser] = useDeleteUserMutation();

  const users = data?.innerData?.manager || null;
  const organization = data?.innerData?.organization || null;

  // Handle change status
  const handleStatusChange = async (id) => {
    try {
      // confirm change status
      let msg = "Вы хотите подтвердить следующий профиль?";
      if (!window.confirm(msg)) return;
      let set = { id, status: "active" };
      const { data = null, error = null } = await changeStatus(set);
      msg = data?.message || error?.message || "Failed to change status";
      if (error) return EnSn(msg, { variant: data?.variant });
      EnSn(msg, { variant: data?.variant });
    } catch (error) {
      return EnSn("Failed to change status", { variant: "error" });
    }
  };

  // Handle delete user
  const handleDeleteUser = async (id) => {
    try {
      // confirm delete
      let msg = "Вы хотите удалить следующий профиль?";
      if (!window.confirm(msg)) return;
      const { data = null, error = null } = await deleteUser(id);
      msg = data?.message || error?.message || "Failed to delete user";
      if (error) return EnSn(msg, { variant: data?.variant });
      EnSn(msg, { variant: data?.variant });
      alert("User deleted");
    } catch (error) {
      return EnSn("Failed to delete user", { variant: "error" });
    }
  };

  if (!users?.length && !organization?.length && !isLoading) {
    return (
      <div className="home">
        <div className="home__content">
          <h1 style={{ lineHeight: "1.5" }}>
            В настоящее время новых пользователей и организаций нет. Как только
            появится новая информация, она будет отображена здесь.
          </h1>
        </div>
      </div>
    );
  }

  return (
    <div className="home">
      <div
        className="home__content"
        style={{ display: users?.length ? "flex" : "none" }}
      >
        <h1>Верификация пользователей:</h1>

        <div className="home__list">
          <ol className="list_users">
            <li className="list_users__header">
              <p className="w150">ФИО</p>
              <p className="w150">Почта</p>
              <p className="w150">Телефон</p>
              <p className="w150">Организация</p>
            </li>

            {users?.map((user) => {
              return (
                <li className="list_users__item" key={user?.id}>
                  <p className="w150">
                    {`${user.fristname} ${user?.lastname} ${user.patronymic}`}
                  </p>
                  <p className="w150">{user?.email}</p>
                  <p className="w150">{user?.phone}</p>
                  <p>{user?.org}</p>
                  <div className="list_users__item__actions">
                    <button>Открыть</button>
                    <button onClick={() => handleStatusChange(user?.id)}>
                      {status(user?.status)}
                    </button>
                    <button onClick={() => handleDeleteUser(user?.id)}>
                      Удалить
                    </button>
                  </div>
                </li>
              );
            })}
          </ol>
        </div>
      </div>

      <div
        className="home__content"
        style={{ display: organization?.length ? "flex" : "none" }}
      >
        <h1>Верификация организаций:</h1>

        <div className="home__list">
          <ol className="list_orgs">
            <li className="list_orgs__header">
              <p className="w180">Почта</p>
              <p className="w450">Наименование</p>
              <p className="w150">ИНН</p>
            </li>

            {organization?.map((org) => {
              return (
                <li className="list_orgs__item" key={org?.id}>
                  <p className="w180">{org?.email}</p>
                  <p className="w450">{org?.name}</p>
                  <p>{org?.inn}</p>
                  <div className="list_orgs__item__actions">
                    <button>Открыть</button>
                    <button onClick={() => handleStatusChange(org?.id)}>
                      {status(org?.status)}
                    </button>
                    <button onClick={() => handleDeleteUser(org?.id)}>
                      Удалить
                    </button>
                  </div>
                </li>
              );
            })}
          </ol>
        </div>
      </div>
    </div>
  );
};

function status(status = null) {
  switch (status) {
    case "active":
      return (status = "Активный");
    case "pending":
      return (status = "Подтвердить");
    case "blocked":
      return (status = "Заблокирован");
    case "suspended":
      return (status = "Приостановлен");
    default:
      return (status = "Неизвестно");
  }
}
