import React, { useState } from "react";
import "./archive.css";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { enqueueSnackbar as EnSn } from "notistack";
import { useGetCalculatorsByArchiveQuery, useDeleteCalculatorMutation, useInWorkCalculatorMutation, useIsPaidCalculatorMutation, useSendPaidCalculatorMutation  } from '../../context/service/calculator.service';
import { useGetUserByIdQuery  } from '../../context/service/user.service';
import Pagination from './Pagination';
import { Link } from 'react-router-dom';
import { PatternFormat, NumericFormat } from "react-number-format";

export const Archive = () => {
    document.title = 'Архив расчетов';
    const [page, setPage] = React.useState(1);
    const { data, error, isLoading } = useGetCalculatorsByArchiveQuery({ page});
    const [deleteCalculator] = useDeleteCalculatorMutation();
    const [inWorkCalculator] = useInWorkCalculatorMutation();
    const [isPaidCalculator] = useIsPaidCalculatorMutation();
    const [sendPaid] = useSendPaidCalculatorMutation();

    const archive = data?.innerData?.results || [];
    const totalCount = data?.innerData?.totalCount || 0; // Общее количество элементов
    const pageSize = data?.innerData?.pageSize || 10; // Количество элементов на странице
    const totalPages = data?.innerData?.totalPages; // Рассчитываем общее количество страниц
    const user = JSON.parse(localStorage.getItem("user"));


    // Обработчик изменения страницы
    const handlePageChange = (newPage) => {
        setPage(newPage); // Обновляем состояние страницы
    };

    if (isLoading) return <div>Загрузка...</div>;
    if (error) return <div>Ошибка загрузки архивов</div>;

    const handleSendToWork = async (id) => {
      const confirmed = window.confirm("Вы уверены, что хотите отправить в работу этот расчет?");
      
      if (!confirmed) {
        return;
      }

      let msg, variant;
      const { data = null, error = null } = await inWorkCalculator(id);

      msg = data ? data.message : error?.data?.message;
      variant = data ? data.variant : error?.data?.variant;

      if (error) return EnSn(msg, { variant });
      EnSn(msg, { variant });
    };

    const handleDeleteCalc = async (id) => {
      const confirmed = window.confirm("Вы уверены, что хотите удалить этот расчет?");
      
      if (!confirmed) {
        return;
      }

      let msg, variant;
      const { data = null, error = null } = await deleteCalculator(id);

      msg = data ? data.message : error?.data?.message;
      variant = data ? data.variant : error?.data?.variant;

      if (error) return EnSn(msg, { variant });
      EnSn(msg, { variant });
    };

    const SendPaid = async (id) => {

    };

    const changePaid = async(id, paid) => {
      const text = (paid == 1) ? 'Вы уверены, что хотите сменить статус на оплачен?' : 'Вы уверены, что хотите сменить статус на  не оплачен?';
      const confirmed = window.confirm(text);
      
      if (!confirmed) {
        return;
      }

      let msg, variant;
      console.log(paid);
      const { data = null, error = null } = await isPaidCalculator({id, paid});

      msg = data ? data.message : error?.data?.message;
      variant = data ? data.variant : error?.data?.variant;

      if (error) return EnSn(msg, { variant });
      EnSn(msg, { variant });
    };

  return (
    <div className="archive">
      <h1>Архив расчетов</h1>

      <div className="archive__container">
        <ol className="archive__list">
          <li className="archive__header">
            <p className="w75">id</p>
            <p className={user?.role === 'admin' ? 'w140' : 'w170'}>ФИО</p>
            <p className={user?.role === 'admin' ? 'w140' : 'w170'}>{user?.role === 'admin' ? 'ИНН' : 'Материал и цвет'}</p>
            <p className="w90">Дата</p>
            <p className="w90">Сумма у обработчика</p>
            <p className="w90">Сумма</p>
            <p className="w90">Наценка</p>
            <p className="w90">Отправлен в расчет?</p>
            {(user?.role === 'admin') && (
              <p className="w60">Оплачен</p>
            )}
          </li>

          {archive.map((item) => {
            const date = new Date(item.date);
            const formattedDate = date.toLocaleDateString('ru-RU'); // Форматируем дату в "ДД.ММ.ГГГГ"
            const color = JSON.parse(item.color);
            const material = JSON.parse(item.material);

            return (
              <li className="archive__item" key={item.id}>
                <p className="w75">{item.id}</p>
                <p className={user?.role === 'admin' ? 'w140' : 'w170'}>
                  {user?.role === 'admin' ? (
                    <>
                      {item.lastname} {item.firstname ? item.firstname.charAt(0) : ''}. {item.patronymic ? item.patronymic.charAt(0) : ''}.
                    </>
                  ) : (
                    item.fullname
                  )}
                </p>
                <p className={user?.role === 'admin' ? 'w140' : 'w170'}>
                  {user?.role === 'admin' ? (
                    item.inn // Если админ, выводим ИНН
                  ) : (
                    <>
                      {material ? material.name : 'Цвет не указан'}
                      <br />
                      {color ? color.name : 'Цвет не указан'}
                    </>
                  )}
                </p>
                <p className="w90">{formattedDate}</p>
                <p className="w90">
                  <NumericFormat
                    value={item.material_price + item.extras_price}
                    allowLeadingZeros
                    thousandSeparator=" "
                    suffix=" ₽"
                    displayType="text"
                  />
                </p>
                <p className="w90">
                  <NumericFormat
                    value={item.material_price + item.extras_price - item.markup_price}
                    allowLeadingZeros
                    thousandSeparator=" "
                    suffix=" ₽"
                    displayType="text"
                  />
                </p>
                <p className="w90">
                  <NumericFormat
                    value={item.markup_price}
                    allowLeadingZeros
                    thousandSeparator=" "
                    suffix=" ₽"
                    displayType="text"
                  />
                </p>
                <p className="w90" style={{ borderRight: "none" }}>
                  {item.status != 0 ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
                </p>
                {(user?.role === 'admin') && (
                  <p className="w60" style={{ borderRight: "none" }}>
                  {item.paid === '1' ? <button className="no_button"  onClick={() => changePaid(item.id, '0')}><MdCheckBox /></button> : <button className="no_button"  onClick={() => changePaid(item.id, '1')}><MdCheckBoxOutlineBlank /></button>}
                  </p>
                )}

                <div className="archive__actions">

                  {(item.status === 0 && user?.role !== 'admin') && (
                    <button onClick={() => handleSendToWork(item.id)}>Отправить в работу</button>
                  )}
                  <Link to={`/archive/item/${item.id}`}>
                    <button>Открыть</button>
                  </Link>
                  {(user?.role === 'admin') && (
                    <button onClick={() => SendPaid(item.id)}>Отправить</button>
                  )}
                  <button onClick={() => handleDeleteCalc(item.id)}>Удалить</button>
                </div>
              </li>
            );
          })}
        </ol>
      </div>
            {totalPages > 1 && (
                <Pagination
                    currentPage={page}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            )}
    </div>
  );
};
