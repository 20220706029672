import React from "react";
import "./organization.css";
import { enqueueSnackbar as EnSn } from "notistack";
import { useGetUsersQuery } from "../../../context/service/user.service";
import { useDeleteUserMutation } from "../../../context/service/user.service";
import { useChangeStatusMutation } from "../../../context/service/auth.service";

export const Organization = () => {
  const { data = null, isLoading } = useGetUsersQuery();
  const [changeStatus] = useChangeStatusMutation();
  const [deleteUser] = useDeleteUserMutation();
  const organization = data?.innerData?.organization || null;

  // Handle change status
  const handleStatusChange = async (org) => {
    try {
      // confirm change status
      let action = org?.status === "active" ? "suspended" : "active";
      let set = { id: org.id, status: action };

      const { data = null, error = null } = await changeStatus(set);
      let msg = data?.message || error?.data?.message;
      if (error) return EnSn(msg, { variant: data?.variant });
      EnSn(msg, { variant: data?.variant || error?.data?.variant });
    } catch (error) {
      return EnSn("Failed to change status", { variant: "error" });
    }
  };

  // Handle delete user
  const handleDeleteUser = async (id) => {
    try {
      // confirm delete
      let msg = "Вы хотите удалить следующий профиль?";
      if (!window.confirm(msg)) return;
      const { data = null, error = null } = await deleteUser(id);
      msg = data?.message || error?.message || "Failed to delete user";
      if (error) return EnSn(msg, { variant: data?.variant });
      EnSn(msg, { variant: data?.variant || error?.data?.variant });
    } catch (error) {
      return EnSn("Failed to delete user", { variant: "error" });
    }
  };

  if (!organization?.length && !isLoading) {
    return (
      <div className="organization">
        <div className="home__content">
          <h1 style={{ lineHeight: "1.5" }}>
            В настоящее время организаций нет. Как только появится новая
            информация, она будет отображена здесь.
          </h1>
        </div>
      </div>
    );
  }

  return (
    <div className="organization">
      <div className="home__content">
        <h1>Список организаций:</h1>

        <div className="home__list">
          <ol className="list_orgs">
            <li className="list_orgs__header">
              <p className="w180">Почта</p>
              <p className="w450">Наименование</p>
              <p className="w150">ИНН</p>
            </li>

            {organization?.map((org) => {
              return (
                <li className="list_orgs__item" key={org?.id}>
                  <p className="w180">{org?.email}</p>
                  <p className="w450">{org?.name}</p>
                  <p>{org?.inn}</p>
                  <div className="list_orgs__item__actions">
                    <button>Открыть</button>
                    <button onClick={() => handleStatusChange(org)}>
                      {org?.status === "active" ? "Отключить" : "Активировать"}
                    </button>
                    <button onClick={() => handleDeleteUser(org?.id)}>
                      Удалить
                    </button>
                  </div>
                </li>
              );
            })}
          </ol>
        </div>
      </div>
    </div>
  );
};
