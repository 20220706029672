import { apiSlice } from "./api.service";

export const adminApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Login service: POST - /auth/login
    login: builder.mutation({
      query: (body) => ({
        url: "/auth/login",
        method: "POST",
        body,
      }),
    }),

    // register service: POST - /auth/register
    register: builder.mutation({
      query: (body) => ({
        url: "/auth/register",
        method: "POST",
        body,
      }),
    }),

    // change status service: PATCH - /auth/change/status/:id
    changeStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `/auth/change/status/${id}`,
        method: "PATCH",
        body: { status: status || null },
      }),

      invalidatesTags: ["update_user"],
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useChangeStatusMutation,
} = adminApi;
