import React from "react";
import "./user.css";
import { useGetUsersQuery } from "../../../context/service/user.service";
import { useDeleteUserMutation } from "../../../context/service/user.service";
import { useChangeStatusMutation } from "../../../context/service/auth.service";
import { enqueueSnackbar as EnSn } from "notistack";

export const User = () => {
  const { data = null, isLoading } = useGetUsersQuery();
  const [changeStatus] = useChangeStatusMutation();
  const [deleteUser] = useDeleteUserMutation();

  const users = data?.innerData?.manager || null;

  // Handle change status
  const handleStatusChange = async (user) => {
    try {
      // confirm change status
      let action = user.status === "active" ? "suspended" : "active";
      let set = { id: user.id, status: action };
      const { data = null, error = null } = await changeStatus(set);
      let msg = data?.message || error?.data?.message;
      let variant = data?.variant || error?.data?.variant;
      if (error) return EnSn(msg, { variant });
      EnSn(msg, { variant: data?.variant || error?.data?.variant });
    } catch (error) {
      return EnSn("Failed to change status", { variant: "error" });
    }
  };

  // Handle delete user
  const handleDeleteUser = async (id) => {
    try {
      // confirm delete
      let msg = "Вы хотите удалить следующий профиль?";
      if (!window.confirm(msg)) return;
      const { data = null, error = null } = await deleteUser(id);
      msg = data?.message || error?.data?.message;
      let variant = data?.variant || error?.data?.variant;
      if (error) return EnSn(msg, { variant });
      EnSn(msg, { variant });
      alert("User deleted");
    } catch (error) {
      return EnSn("Failed to delete user", { variant: "error" });
    }
  };

  if (!users?.length && !isLoading) {
    return (
      <div className="user">
        <div className="home__content">
          <h1 style={{ lineHeight: "1.5" }}>
            В настоящее время пользователей нет. Как только появится новая
            информация, она будет отображена здесь.
          </h1>
        </div>
      </div>
    );
  }

  return (
    <div className="user">
      <div className="home__content">
        <h1>Список пользователей:</h1>

        <div className="home__list">
          <ol className="list_users">
            <li className="list_users__header">
              <p className="w150">ФИО</p>
              <p className="w150">Почта</p>
              <p className="w150">Телефон</p>
              <p className="w150">Организация</p>
            </li>

            {users?.map((user) => {
              return (
                <li className="list_users__item" key={user?.id}>
                  <p className="w150">
                    {`${user.fristname} ${user?.lastname} ${user.patronymic}`}
                  </p>
                  <p className="w150">{user?.email}</p>
                  <p className="w150">{user?.phone}</p>
                  <p>{user?.org}</p>
                  <div className="list_users__item__actions">
                    <button>Открыть</button>
                    <button onClick={() => handleStatusChange(user)}>
                      {user?.status === "active" ? "Отключить" : "Активировать"}
                    </button>
                    <button onClick={() => handleDeleteUser(user?.id)}>
                      Удалить
                    </button>
                  </div>
                </li>
              );
            })}
          </ol>
        </div>
      </div>
    </div>
  );
};
