import React from 'react';
import "./pagination.css";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const createPageNumbers = () => {
        let pages = [];
        let startPage = Math.max(1, currentPage - 2);
        let endPage = Math.min(totalPages, currentPage + 2);

        if (startPage > 1) {
        pages.push(1);
        if (startPage > 2) pages.push("..."); // Условие для добавления многоточия
        }

        for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
        }

        if (endPage < totalPages) {
        if (endPage < totalPages - 1) pages.push("..."); // Добавляем многоточие, если есть пропущенные страницы
        pages.push(totalPages);
        }

    return pages;
    };

    return (
        <div className="pagination">
                    <button className="prev" onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 14L5 8L11 2" stroke="#A0A8B5" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        Назад
                    </button>
                    {createPageNumbers().map((page, index) => (
                    <span key={index} onClick={typeof page === 'number' ? () => onPageChange(page) : undefined}  style={{ cursor: typeof page === 'number' ? 'pointer' : 'default', fontWeight: currentPage === page ? 'bold' : 'normal' }}>
                        {page}</span>
            ))}
                    <button className="next" onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                        Вперед
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 13L7 7L1 1" stroke="#23262A" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
        </div>
    );
};

export default Pagination;