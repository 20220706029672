import React from 'react';
import ExcelJS from 'exceljs';
import { useGetProductQuery, useUpdateProductMutation, useCreateProductMutation } from "../../../context/service/product.service";


export const ExportToExcel = () => {
    const { data, error, isLoading } = useGetProductQuery('all');
    const exptr = data?.innerData || [];


    const exportToExcel = async () => {
        if (isLoading) {
            console.log("Loading...");
            return; // Не продолжаем, пока данные загружаются
        }

        if (error) {
            console.error("Error: ", error.message);
            return; // Не продолжаем, если произошла ошибка
        }

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Products'); // Задайте имя листа

        // Заголовки колонок
        worksheet.columns = [
            { header: 'ID', key: 'id', width: 15 },
            { header: 'Name', key: 'name', width: 30 },
            { header: 'Price', key: 'price', width: 15 },
            { header: 'Artiarticle Number', key: 'artiarticle_number', width: 20 },
            { header: 'Created At', key: 'created_at', width: 20 },
            { header: 'Description', key: 'description', width: 30 },
            { header: 'Image URL', key: 'image', width: 50 },
            { header: 'Params', key: 'param', width: 30 },
            { header: 'Type', key: 'type', width: 15 },
        ];

        // Собираем данные из таблицы
        exptr.forEach(product => {
            worksheet.addRow({
                id: product.id,
                name: product.name,
                price: product.price,
                artiarticle_number: product.artiarticle_number,
                created_at: new Date(product.created_at).toLocaleString(), // Форматируем дату
                description: product.description || '', // для null значений
                image: product.image,
                param: product.param,
                type: product.type,
            });
        });

        // Сохранение файла
        try {
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            const link = document.createElement('a');

            // Форматируем текущую дату в строку
            const now = new Date();
            const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
            
            link.href = window.URL.createObjectURL(blob);
            link.download = `products.${formattedDate}.xlsx`; // Название файла с текущей датой
            link.click();
        } catch (e) {
            console.error("Error while exporting to Excel: ", e);
        }
    };

    return (
          <button onClick={exportToExcel}>
            <img src="/icons/save.svg" alt="" />
            <span>Скачать</span>
          </button>
    );
};



export const ImportExport = () => {
  const { data, error, isLoading } = useGetProductQuery('all');
  const exptr = data?.innerData || [];
    const [updateProduct] = useUpdateProductMutation();
    const [createProduct] = useCreateProductMutation();

    const handleButtonClick = () => {
        document.getElementById('file-upload').click(); // Имитируем нажатие на input
    };

    const handleFileUpload = async (file, data, updateProduct, createProduct) => {
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(await file.arrayBuffer());
        const worksheet = workbook.worksheets[0]; // Предполагаем, что данные на первом листе

        let createdCount = 0;
        let updatedCount = 0;

        // Используем цикл for...of для асинхронной обработки
        for (const row of worksheet.getRows(2, worksheet.rowCount - 1)) {
            const values = row.values.map(value => (value === undefined ? '' : value));
            const isEmptyRow = values.every(value => value === '');

            if (isEmptyRow) {
                continue; // Пропускаем пустую строку
            }

            const [, id, name, price, artiarticle_number, created_at, description, image, params, type] = row.values;
            const foundProduct = data.find(product => product.id === id);

            let set = { 
                name: name, 
                price: price, 
                type: type, 
                description: description,
                param: params,
                artiarticle_number: artiarticle_number 
            };

            try {
                if (foundProduct) {

                    set = { id: id, body: set };
                    await updateProduct(set);
                    updatedCount++; 

                } else {
                    // Продукт не найден - создаем новую запись
                    await createProduct(set);


                    createdCount++;
                }
            } catch (error) {
                console.error(`Ошибка при обработке строки ${row.number}:`, error);
            }
        }
        alert(`Создано товаров: ${createdCount}\nОбновлено товаров: ${updatedCount}`);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
           const confirmImport = window.confirm(`Вы уверены, что хотите импортировать файл ${file.name}?`);
          if (confirmImport) {
            handleFileUpload(file, exptr, updateProduct, createProduct);
          } else {
            console.log('Импорт отменен пользователем.');
          }
          event.target.value = '';
        }
    };

  return (
        <div>
            <input
                type="file"
                id="file-upload"
                accept=".xlsx"
                style={{ display: 'none' }} // Скрываем input
                onChange={handleFileChange}
            />
            <button onClick={handleButtonClick}><img src="/icons/install.svg" alt="" />Загрузить</button>
        </div>
  );
};
